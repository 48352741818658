import React from "react";
import "./CustomRadioButton.css";
import { Typography } from "@mui/material";

const CustomRadioButton = ({ label, checked, onChange, value }) => {
  return (
    <label className="custom-radio">
      <input type="radio" checked={checked} onChange={onChange} value={value} />
      <span className="custom-radio-button"></span>
      <Typography
        sx={{
          marginTop: "2px",
          color: "#5e718b",
          fontSize: "12px !important",
          fontStyle: "normal",
          fontFamily: "Heebo",
          fontWeight: "400 !important",
          lineHeight: "160%",
        }}
      >
        {label}
      </Typography>
    </label>
  );
};

export default CustomRadioButton;
