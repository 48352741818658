import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ name, handleOnChange, ...props }) => (
  <div className="toggle-switch">
    <input
      type="checkbox"
      onChange={handleOnChange}
      className="toggle-switch-checkbox"
      name={name}
      id={name}
      {...props}
    />
    <label className="toggle-switch-label" htmlFor={name}>
      <span className="toggle-switch-inner" />
      <span className="toggle-switch-switch" />
    </label>
  </div>
);
export default ToggleSwitch;
