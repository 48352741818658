import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { ReactComponent as UpArrow } from "../../assets/svg/upArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/svg/downArrow.svg";
import PrintIcon from "../../assets/PrintIcon";
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 19px 6px 18px",
  border: "1px solid rgb(123, 131, 235)",
  borderRadius: "50px",
  lineHeight: "160%",
  fontWeight: "700",
  fontFamily: "Heebo",
  "&:hover": {
    backgroundColor: "#7B83EB",
    borderColor: "#7B83EB",
    color: "white",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#7B83EB",
    border: "#7B83EB",
  },
  "&:focus": {
    boxShadow: "none",
  },
});

const CustomButton = ({
  btnText,
  variant,
  isUpIcon = false,
  isDownIcon = false,
  isPrintIcon = false,
  ...props
}) => {
  return (
    <BootstrapButton variant={variant} {...props}>
      {btnText}
      {isUpIcon && <UpArrow />}
      {isDownIcon && <DownArrow />}
      {isPrintIcon && <PrintIcon />}
    </BootstrapButton>
  );
};
export default CustomButton;
