import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

const CheckoutSummary = ({ isSuccess }) => {
  const isPaymentSuccess = isSuccess;
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        width: "100% ",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isPaymentSuccess ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "4em",
              letterSpacing: "3px",
              color: "rgb(123, 131, 235)",
              mb: "20px",
              fontWeight: "700",
              fontFamily: "Heebo",
            }}
          >
            Thank you !
          </Typography>
          <Typography
            sx={{
              fontSize: "2em",
              letterSpacing: "3px",
              color: "black",
              mb: "100px",
              fontWeight: "400",
              fontFamily: "Heebo",
            }}
          >
            Your payment is Received.
          </Typography>
          <CustomButton
            variant={"contained"}
            btnText={
              <Box
                onClick={() => {
                  navigate("/");
                  window.location.reload();
                  localStorage.clear();
                }}
                style={{ textDecoration: "none", color: "white" }}
              >
                Create New User
              </Box>
            }
          />
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "4em",
              letterSpacing: "3px",
              color: "rgb(123, 131, 235)",
              mb: "20px",
              fontWeight: "700",
              fontFamily: "Heebo",
            }}
          >
            Oops!
          </Typography>
          <Typography
            sx={{
              fontSize: "2em",
              letterSpacing: "3px",
              color: "black",
              mb: "100px",
              fontWeight: "400",
              fontFamily: "Heebo",
            }}
          >
            Your payment failed.
          </Typography>

          <CustomButton
            variant={"contained"}
            btnText={
              <Box
                onClick={() => {
                  navigate("/");
                  window.location.reload();
                  localStorage.clear();
                }}
                style={{ textDecoration: "none", color: "white" }}
              >
                Try again
              </Box>
            }
          />
        </>
      )}
    </Stack>
  );
};
export default CheckoutSummary;
