import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AddonsFullContainer from "../../components/AddonsFullContainer";
import ContractsPlans from "../../components/ContractsPlans";
import FullScreenAddonsModal from "../../components/FullScreenAddonsModal";
import InfoBlock from "../../components/InfoBlock";

const Addons = ({
  handleChange,
  signupdetails,
  setFullScreen,
  isFullScreen,
}) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <Stack sx={{ overflow: "hidden", position: "relative" }}>
      <Typography
        sx={{
          color: "#5E718B",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "160%",
        }}
      >
        Plan length determines your set up cost, porting cost and number
        provisioning cost.
      </Typography>
      <ContractsPlans
        handleChange={handleChange}
        signupdetails={signupdetails}
      />
      <InfoBlock />
      <AddonsFullContainer
        handleChange={handleChange}
        signupdetails={signupdetails}
        activeIndex={activeIndex}
        setFullScreen={setFullScreen}
        isFullScreen={isFullScreen}
        setActiveIndex={(index) => {
          setActiveIndex(index);
          handleChange("activeAddons", index);
        }}
      />
      <FullScreenAddonsModal
        handleChange={handleChange}
        signupdetails={signupdetails}
        activeIndex={activeIndex}
        open={isFullScreen}
        isFullScreen={isFullScreen}
        handleClose={setFullScreen}
        setActiveIndex={(index) => {
          setActiveIndex(index);
          handleChange("activeAddons", index);
        }}
      />
    </Stack>
  );
};
export default Addons;
