import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import StripeCheckout from "./components/StripeCheckout/StripeCheckout";
import NotFound from "./pages/NotFound";
import Signup from "./pages/Signup";
import { AppContext } from "./store/store";
import { dummyConversionRates } from "./utils/Constants";
import theme from "./utils/ThemeColors";
import { fetchConversionRates } from "./utils/Country";

const App = () => {
  const [latestConversionRates, setLatestConversionRates] =
    useState(dummyConversionRates);
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("selectedCurrency") || "USD"
  );
  const [conversionRates, setConversionRates] = useState(
    dummyConversionRates["USD"]
  );
  const [defaultConversionRates, setDefaultConversionRates] = useState();
  useEffect(() => {
    const keyData = Object.keys(dummyConversionRates);
    let latestData = {};
    keyData.forEach(async (element) => {
      setTimeout(async () => {
        const data = await fetchConversionRates(element);
        latestData[element] = data;
      }, 200);
    });
    localStorage.setItem("is_checkout", "false");
    setTimeout(() => {
      setLatestConversionRates(latestData);
    }, 2200);
  }, []);

  useEffect(() => {
    setDefaultConversionRates(latestConversionRates);
  }, [latestConversionRates]);

  const initialData = {
    currency: selectedCurrency,
    conversionRates,
    setCurrencyData(payload) {
      setConversionRates(payload);
    },
    defaultConversionRates,
    setDefaultConversion(payload) {
      setDefaultConversionRates(payload);
    },
    setCurrency(payload) {
      setSelectedCurrency(payload);
    },
  };
  return (
    <AppContext.Provider value={initialData}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signup />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/checkout" element={<StripeCheckout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
