import React, { useState } from "react";
import { ReactComponent as BackArrow } from "../../assets/svg/left-back.svg";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import headset from "../../assets/png/headset.png";
import ivr from "../../assets/png/ivr.png";
import meeting from "../../assets/png/meeting.png";
import PersonIcons from "../../assets/png/Vector.png";
import emptyCart from "../../assets/png/emptyCart.png";

import { useNavigate } from "react-router-dom";

const CartPopup = ({
  signupdetails,
  handleIncrementOneOff,
  handleDecrementOneOff,
  handleIncrementMonthly,
  handleDecrementMonthly,
  handleRemoveOneOff,
  handleRemoveMonthly,
  activeStep,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const isAbove720 = useMediaQuery("(min-width:720px)");
  // Dynamically construct cartItems based on signupdetails
  const constructCartItems = () => {
    const oneOffItems = [];
    const monthlyItems = [];

    // Map the one-off items from signupdetails
    if (signupdetails.handset) {
      oneOffItems.push({
        name: "Handset",
        description: "Lorem Ipsum",
        price: 30,
        quantity: signupdetails.handset,
        image: headset,
      });
    }
    if (signupdetails.handset1) {
      oneOffItems.push({
        name: "Handset 1",
        description: "Lorem Ipsum",
        price: 30,
        quantity: signupdetails.handset1,
        image: headset,
      });
    }
    if (signupdetails.total_ivr_flow) {
      oneOffItems.push({
        name: "IVR Call Flow Setup",
        description: "Lorem Ipsum",
        price: 20,
        quantity: signupdetails.total_ivr_flow,
        image: ivr,
      });
    }
    if (signupdetails.meeting_device) {
      oneOffItems.push({
        name: "Meeting Room Devices",
        description: "Lorem Ipsum",
        price: 100,
        quantity: signupdetails.meeting_device,
        image: meeting,
      });
    }

    // Map the monthly items from signupdetails
    if (signupdetails.basic_call_user) {
      monthlyItems.push({
        name: "Basic call recording",
        description: "Lorem Ipsum",
        price: 180,
        icon: <PersonIcon />,
        quantity: signupdetails.basic_call_user,
      });
    }
    if (signupdetails.premium_call_user) {
      monthlyItems.push({
        name: "Premium call recording",
        description: "Lorem Ipsum",
        price: 180,
        icon: <PersonIcon />,
        quantity: signupdetails.premium_call_user,
      });
    }

    return { oneOffItems, monthlyItems };
  };

  // Get the dynamic cart items
  const { oneOffItems, monthlyItems } = constructCartItems();

  if (!isOpen) return null;

  return (
    <>
      {oneOffItems.length + monthlyItems.length > 0 ? (
        <Box
        // className="custom-popup-box"
          // sx={{
          //   position: "fixed",
          //   top: "50%",
          //   left: "35%", }}
          className={!isAbove720 ? "" : "custom-popup-box"}
          sx={{
            position: "fixed",
            top: !isAbove720
              ? activeStep === 0
                ? "78%"
                : activeStep === 1
                ? "41%"
                : activeStep === 2
                ? "37%"
                : activeStep === 3
                ? "50%"
                : "50%"
              : "50%",

            left: !isAbove720
              ? activeStep === 0
                ? "50%"
                : activeStep === 1
                ? "50%"
                : activeStep === 2
                ? "50%"
                : activeStep === 3
                ? "50%"
                : "35%"
              : "35%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            borderRadius: "16px",
            padding: isAbove720 ? "20px 45px" : "20px 15px",
            width: "100%",
            zIndex: 1000,
            border: "2px solid #8828FF",
            background: "#F0F1FF",
            gap: "20px",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: isAbove720 ? "60px" : "30px",
              padding: isAbove720 ? "0px" : "0 10px",
            }}
          >
            <Typography
              variant="h6"
              fontFamily="DM Sans"
              fontWeight="700"
              fontSize={isAbove720 ? "55px" : "26px"}
              marginTop={isAbove720 ? "20px" : "0px"}
              lineHeight="31.2px"
              color="#0C2A52"
              gutterBottom
            >
              Your Cart
            </Typography>
            <IconButton onClick={() => setIsOpen(false)}>
              {/* , color: "#0C2A52 */}
              <CloseIcon
                sx={{
                  fontSize: 35,
                  border: "1px solid #7B83EB",
                  borderRadius: "50%",
                  color: "#7B83EB",
                }}
              />
            </IconButton>
          </Box>

          {/* Cart Items Section */}
          <Grid container spacing={4}>
            {/* One-off Cost Items */}
            <Grid
              item
              xs={6}
              sx={{
                border: isAbove720 ? "1px solid #7B83EB80" : "none",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight="600"
                fontSize="20px"
                color="#0C2A52"
                gutterBottom
                className="font-dm"
              >
                One-off Cost Items
              </Typography>
              {isAbove720 && <Divider sx={{ mb: 2 }} />}
              <Box
                sx={{
                  height: "48vh",
                  maxHeight: "48vh", // Set maximum height
                  overflowY: "auto", // Enable vertical scrolling
                }}
              >
                {oneOffItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: isAbove720 ? "none" : "1px solid #7B83EB80",
                      marginBottom: isAbove720 ? "none" : "10px",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    className="cart-item"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Box sx={{ flex: 1, ml: 2 }}>
                        <Typography fontWeight="700" className="item-name">
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className="item-desc"
                          color="textSecondary"
                        >
                          {item.description}
                        </Typography>
                        {isAbove720 ? (
                          ""
                        ) : (
                          <Typography fontWeight="600">
                            ${item.price * item.quantity}
                          </Typography>
                        )}
                      </Box>
                      {isAbove720 ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          border="1px solid #7B83EB"
                          borderRadius="4px"
                          width="fit-content"
                          marginRight="10px"
                        >
                          <Button
                            variant="text"
                            onClick={() => handleDecrementOneOff(index, item)}
                            sx={{
                              minWidth: "30px",
                              padding: "4px 8px",
                              borderRadius: "0",
                              borderRight: "1px solid #7B83EB",
                            }}
                          >
                            -
                          </Button>
                          <Typography
                            variant="body1"
                            sx={{
                              minWidth: "30px",
                              // maxHeight: "16px",
                              textAlign: "center",
                              padding: "4px 8px",
                              borderRight: "1px solid #7B83EB",
                            }}
                          >
                            {item.quantity}
                          </Typography>
                          <Button
                            variant="text"
                            onClick={() => handleIncrementOneOff(index)}
                            sx={{
                              minWidth: "30px",
                              padding: "4px 8px",
                              borderRadius: "0",
                            }}
                          >
                            +
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                      {isAbove720 ? (
                        <>
                          <Typography fontWeight="600">
                            ${item.price * item.quantity}
                          </Typography>
                          <IconButton onClick={() => handleRemoveOneOff(index)}>
                            <CloseIcon
                              sx={{
                                fontSize: 25,
                                color: "#7B83EB",
                                border: "2px solid #7B83EB",

                                borderRadius: "5px",
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        ""
                      )}

                      {isAbove720 ? (
                        ""
                      ) : (
                        <Box
                          sx={{
                            flex: 0,
                            textAlign: "end",
                          }}
                        >
                          <IconButton
                            sx={{ marginBottom: "15px" }}
                            onClick={() => handleRemoveOneOff(index)}
                          >
                            <CloseIcon
                              className="button-icon"
                              sx={{
                                fontSize: 25,
                                color: "#7B83EB",
                                border: "2px solid #7B83EB",
                                borderRadius: "5px",
                              }}
                            />
                          </IconButton>
                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px solid #7B83EB"
                            borderRadius="4px"
                            width="fit-content"
                            marginRight="10px"
                          >
                            <Button
                              variant="text"
                              onClick={() => handleDecrementOneOff(index)}
                              sx={{
                                minWidth: "30px",
                                // maxHeight: "16px",
                                padding: "4px 8px",
                                borderRadius: "0",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              -
                            </Button>
                            <Typography
                              variant="body1"
                              sx={{
                                minWidth: "30px",
                                // maxHeight: "16px",
                                textAlign: "center",
                                padding: "4px 8px",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              {item.quantity}
                            </Typography>
                            <Button
                              variant="text"
                              onClick={() => handleIncrementOneOff(index)}
                              sx={{
                                minWidth: "30px",
                                padding: "4px 8px",
                                borderRadius: "0",
                              }}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {isAbove720 && index < oneOffItems.length - 1 && (
                      <Divider sx={{ my: 2 }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
            {/* Monthly Cost Items */}
            <Grid
              item
              xs={6}
              sx={{
                border: isAbove720 ? "1px solid #7B83EB80" : "none",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              {isAbove720 ? (
                <Typography
                  variant="h6"
                  fontWeight="600"
                  fontSize="20px"
                  color="#0C2A52"
                  gutterBottom
                  className="font-dm"
                >
                  Monthly Cost Items
                </Typography>
              ) : (
                monthlyItems.length > 0 && (
                  <Typography
                    variant="h6"
                    fontWeight="600"
                    fontSize="20px"
                    color="#0C2A52"
                    gutterBottom
                    className="font-dm"
                  >
                    Monthly Cost Items
                  </Typography>
                )
              )}
              {isAbove720 && <Divider sx={{ mb: 2 }} />}
              <Box
                sx={{
                  height: "48vh",
                  maxHeight: "48vh", // Set maximum height
                  overflowY: "auto", // Enable vertical scrolling
                }}
              >
                {monthlyItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: isAbove720 ? "none" : "1px solid #7B83EB80",
                      marginBottom: isAbove720 ? "none" : "10px",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    className="cart-item"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={PersonIcons}
                            alt={item.name}
                            style={{ width: "50px", height: "50px" }}
                          />
                        </Box>
                        <Box sx={{ ml: 5 }}>
                          <Typography fontWeight="700" className="item-name">
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="item-desc"
                          >
                            {item.description}
                          </Typography>
                          {isAbove720 ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              border="1px solid #7B83EB"
                              borderRadius="4px"
                              width="fit-content"
                              marginTop="5px"
                            >
                              <Button
                                variant="text"
                                onClick={() => handleDecrementMonthly(index)}
                                sx={{
                                  minWidth: "30px",
                                  padding: "4px 8px",
                                  borderRadius: "0",
                                  borderRight: "1px solid #7B83EB",
                                }}
                              >
                                -
                              </Button>
                              <Typography
                                variant="body1"
                                sx={{
                                  minWidth: "30px",
                                  textAlign: "center",
                                  padding: "4px 8px",
                                  borderRight: "1px solid #7B83EB",
                                }}
                              >
                                {item.quantity}
                              </Typography>
                              <Button
                                variant="text"
                                onClick={() => handleIncrementMonthly(index)}
                                sx={{
                                  minWidth: "30px",
                                  padding: "4px 8px",
                                  borderRadius: "0",
                                }}
                              >
                                +
                              </Button>
                            </Box>
                          ) : (
                            <Typography fontWeight="600">
                              $ {item.price * item.quantity}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      {isAbove720 ? (
                        ""
                      ) : (
                        <Box sx={{ mr: 1, textAlign: "end" }}>
                          <IconButton
                            sx={{ marginBottom: "15px" }}
                            onClick={() => handleRemoveMonthly(index)}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: 15,
                                color: "#7B83EB",
                                border: "2px solid #7B83EB",
                                borderRadius: "5px",
                              }}
                            />
                          </IconButton>
                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px solid #7B83EB"
                            borderRadius="4px"
                            width="fit-content"
                            marginTop="5px"
                          >
                            <Button
                              variant="text"
                              onClick={() => handleDecrementMonthly(index)}
                              sx={{
                                minWidth: "30px",
                                padding: "4px 8px",
                                borderRadius: "0",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              -
                            </Button>
                            <Typography
                              variant="body1"
                              sx={{
                                minWidth: "30px",
                                textAlign: "center",
                                padding: "4px 8px",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              {item.quantity}
                            </Typography>
                            <Button
                              variant="text"
                              onClick={() => handleIncrementMonthly(index)}
                              sx={{
                                minWidth: "30px",
                                padding: "4px 8px",
                                borderRadius: "0",
                              }}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {isAbove720 ? (
                        <>
                          <Box sx={{ ml: 2, textAlign: "center" }}>
                            <IconButton
                              onClick={() => handleRemoveMonthly(index)}
                            >
                              <CloseIcon
                                sx={{
                                  fontSize: 25,
                                  color: "#7B83EB",
                                  marginBottom: "10px",
                                  border: "2px solid #7B83EB",
                                  borderRadius: "5px",
                                }}
                              />
                            </IconButton>
                            <Typography fontWeight="600">
                              $ {item.price * item.quantity}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    {isAbove720 && index < monthlyItems.length - 1 && (
                      <Divider sx={{ my: 2 }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "24px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsOpen(false)}
              sx={{
                width: "10%",
                flexShrink: 0,
                fontSize: "14px",
                borderRadius: "14.5px",
                maxWidth: isAbove720 ? "62px" : "83px",
                textTransform: "none",
                lineHeight: "19.2px",
                fontWeight: "700",
              }}
            >
              <BackArrow />
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/checkout")}
              sx={{
                // width: "30%",
                flexShrink: 0,
                fontSize: isAbove720 ? "12px" : "14px",
                borderRadius: "14.5px",
                maxWidth: "162px",
                minWidth: "162px",
                textTransform: "none",
                fontWeight: "700",
                lineHeight: isAbove720 ? "15px" : "30px",
                padding: isAbove720 ? "0" : "0",
              }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              borderRadius: "16px",
              padding: "20px 15px",
              width: "100%",
              height: "100%",
              zIndex: 1000,
              border: "2px solid #8828FF",
              background: "#F0F1FF",
              display: "flex", // Enable flexbox
              flexDirection: "column", // Stack elements vertically
              justifyContent: "center", // Center content vertically
              alignItems: "center", // Center content horizontally
              textAlign: "center", // Center text inside the container
            }}
          >
            <img
              src={emptyCart}
              alt="Cart Icon"
              style={{
                width: "126px",
                height: "126px",
                marginBottom: "20px", // Add spacing between image and text
              }}
            />
            <Typography
              fontWeight="700"
              className="empty-cart"
              sx={{
                marginBottom: "20px", // Add some margin between text and button
                fontSize: "18px", // Adjust font size for better readability
              }}
            >
              Hey There, Your Cart is Empty!
            </Typography>
            <Button
              className="empty-back-button"
              color="primary"
              onClick={() => setIsOpen(false)}
              sx={{
                width: "50%",
                maxWidth: "212px",
                fontSize: "14px",
                lineHeight: "22.4px",
                textTransform: "none",
                cursor: "pointer",
                borderRadius: "14.5px",
              }}
            >
              Start Shopping
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default CartPopup;
