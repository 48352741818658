import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as Error } from "../../assets/svg/error.svg";
import React from "react";
const InfoBlock = () => (
  <Box
    sx={{
      display: "flex",
      width: "auto",
      alignItems: "center",
      mt: "20px",
    }}
  >
    <Box
      className="infoBlock"
      sx={{
        borderRadius: "13px",
        border: "1px solid #7B83EB",
        pt: "11px",
        pb: "9px",
        px: "14px",
        display: "flex",
        width: "auto",
        alignItems: "center",
        background: "#F0F1FF",
      }}
    >
      <IconButton sx={{ minWidth: "25px", minHeight: "25px" }}>
        <Error />
      </IconButton>
      <Typography
        sx={{
          color: "#5E718B",
          fontSize: "10px",
          fontStyle: "normal",
          fontFamily: "Heebo",
          fontWeight: "400",
          lineHeight: "139.5%",
          ml: "12px",
        }}
      >
        Want to save on telephony set-up cost? Sign up to our 36-month plan and
        we'll remove 100%.
        <br /> Sign up to our 24-month plan and we'll remove 50%. Discount does
        not include hardware.
      </Typography>
    </Box>
  </Box>
);
export default InfoBlock;
