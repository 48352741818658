const mainUrl =
  process.env.REACT_APP_BACKEND_URL ||
  "https://backend.shuttle.eqserver.net/api";

const signup = mainUrl + "/signup";
const location = mainUrl + "/location";
const addons = mainUrl + "/addons";
const signupDetailsApi = mainUrl + "/signupDetails";
const createIntent = mainUrl + "/payments-intent";
const payments = mainUrl + "/payments";
export { signup, location, addons, signupDetailsApi, createIntent, payments };
