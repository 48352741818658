import {
  FormControl,
  InputBase,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React from "react";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 13,
    position: "relative",
    border: "1px solid",
    borderColor: "#7B83EB",
    // color: '#33388d',
    fontSize: 14,
    width: "90%",
    height: "auto",
    marginLeft: "5px",
    padding: "8px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.ochre.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.ochre.main,
    },
    "&:placeholder": {
      color: "#5E718B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "160%",
    },
  },
}));

const InputText = ({
  label,
  width = "100%",
  refInput,
  placeholder,
  defaultValue,
  isRequiredIcon = true,
  name,
  ...props
}) => {
  return (
    <FormControl variant="standard" width={width}>
      <Typography
        htmlFor="bootstrap-input"
        sx={{
          color: "text.secondary",
          fontSize: "14px !important",
          fontWeight: "400",
          marginBottom: "10px",
          marginLeft: "5px",
          lineHeight: "160%",
          fontStyle: "normal",
          fontFamily: "Heebo",
        }}
      >
        {label ? label : placeholder}{" "}
        {isRequiredIcon && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <BootstrapInput
        defaultValue={defaultValue}
        required
        refs={refInput}
        placeholder={placeholder}
        width={width}
        {...props}
      />
    </FormControl>
  );
};
export default InputText;
