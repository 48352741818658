import { Box, Stack } from "@mui/material";
import React from "react";
import CustomButton from "../CustomButton";
import CustomSwitch from "../CustomSwitch";
import SummaryPopup from "../SummaryPopup";

const Footer = ({
  activeStep,
  handleChange,
  signupdetails,
  handleBack,
  onSubmit,
}) => (
  <Box
    className={"stepFooter"}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"space-between"}
    flexWrap={"wrap"}
    height={"auto"}
    sx={{
      mt: { xs: "30px", md: activeStep === 2 ? "30px" : "15px" },
      flexDirection: { md: "row", xs: "row-reverse" },
    }}
  >
    <Box sx={{ width: { xs: "100%", md: "auto" } }}>
      {activeStep === 2 && (
        <Stack className="blockItem">
          <CustomSwitch
            labelName={
              <>
                Do you need{" "}
                <b style={{ marginLeft: "5px", marginRight: "5px" }}>
                  contact centre
                </b>{" "}
                functionality for your business?
              </>
            }
            isRequired={false}
            isInfoIcon={true}
            tooltipTitle={
              "Does your organisation require contact centre functionality for a sales team or support desk? Select yes and our sales team will be in touch to discuss additional software add-on options. "
            }
            name="is_contact_center_business"
            signupdetails={signupdetails}
            checked={
              signupdetails?.addons?.contact_center?.is_contact_center_business
            }
            onChange={() =>
              handleChange(
                "addons.contact_center.is_contact_center_business",
                !signupdetails?.addons?.contact_center
                  ?.is_contact_center_business
              )
            }
          />
          <CustomSwitch
            labelName={
              <>
                Does your business require&nbsp;
                <b>advanced reporting?</b>
              </>
            }
            tooltipTitle={
              "Do you require advanced reporting and analytics on your calls? Select yes and our sales team will be in touch to discuss additional software add-on options. "
            }
            name="advanced_reporting"
            isRequired={false}
            isInfoIcon={true}
            signupdetails={signupdetails}
            checked={
              signupdetails?.addons?.advanced_reporting?.advanced_reporting
            }
            onChange={() =>
              handleChange(
                "addons.advanced_reporting.advanced_reporting",
                !signupdetails?.addons?.advanced_reporting?.advanced_reporting
              )
            }
          />
        </Stack>
      )}
    </Box>
    {activeStep === 2 && (
      <Box
        className="summary-popup"
        sx={{ display: { sx: "flex", md: "none" } }}
      >
        <SummaryPopup isAddons={true} {...signupdetails} isPopup={false} />
      </Box>
    )}
    <Box
      display={"flex"}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
      className={activeStep < 1 ? "justifyEnd" : "justifyBetween"}
    >
      {activeStep > 0 && (
        <CustomButton
          variant="outlined"
          type="button"
          btnText={"Back"}
          className={"backBtn"}
          onClick={handleBack}
          isUpIcon={true}
        />
      )}
      {activeStep < 5 && (
        <CustomButton
          className={activeStep < 4 ? "nextBtn" : "completeSignup"}
          type={"button"}
          sx={{ marginLeft: 2 }}
          variant={activeStep < 4 ? "outlined" : "contained"}
          btnText={activeStep < 4 ? "Next" : "Complete Signup"}
          onClick={onSubmit}
          isDownIcon={activeStep < 4 ? true : false}
        />
      )}
    </Box>
  </Box>
);
export default Footer;

