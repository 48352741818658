/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  InputBase,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { ReactComponent as Minus } from "../../assets/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { AppContext } from "../../store/store";
import { formatCurrencyAmount as formatFn } from "../../utils/Constants";
import { codeCurrencyMap, planAmountMap } from "../../utils/Country";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid",
    borderColor: "#7B83EB",
    borderBottom: "none",
    borderTop: "none",
    fontSize: 14,
    width: "80%",
    textAlign: "center",
    height: "29px",
    padding: "7px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:placeholder": {
      color: "text.secondary",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "160%",
    },
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "180px",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: 12,
  },
}));
const ImageTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: window.innerWidth < 800 ? "500px" : "630px",
    maxWidth: "none",
  },
}));
const CustomCounter = ({
  refInput,
  isPlans = false,
  labelName,
  placeholder,
  boxStyle,
  width,
  isInfoIcon = true,
  onHandleChange,
  tooltipTitle = "",
  isTooltip = true,
  value,
  selectedPlans,
  min,
  stepsOfTen,
  numberOfUsers = 0,
  isAddition = false,
  ...props
}) => {
  const [counterValue, setCounterValue] = useState(value || 0);
  const { currency, conversionRates, defaultConversionRates, setCurrencyData } =
    useContext(AppContext);
  const [conversionRatesNew, setConversionRatesNew] = useState(conversionRates);
  const formatCurrencyAmount = formatFn.bind(conversionRatesNew);
  useEffect(() => {
    if (props.country) {
      getConvertCurrency(codeCurrencyMap[props.country]);
    }
  }, [props.country]);

  const getConvertCurrency = async (from, to) => {
    setCurrencyData(defaultConversionRates[from]);
    setConversionRatesNew(defaultConversionRates[from]);
  };

  const handleOnChange = (event) => {
    if (stepsOfTen && event?.target?.value % 10) {
      if (event?.target?.value.length > 1) {
        setTimeout(() => {
          const ceilValue =
            Math.ceil((event?.target?.value / 10).toFixed(1)).toString() + 0;
          if (event?.target?.value % 10 !== 0 && counterValue % 10 !== 0) {
            setCounterValue(ceilValue);
            onHandleChange(ceilValue);
          }
        }, 1500);
      }
      setCounterValue(event?.target?.value);
      onHandleChange(event?.target?.value);
      return;
    }

    if (!(numberOfUsers !== 0 && numberOfUsers <= event?.target?.value)) {
      if (labelName === "Numbers of users") {
        setCounterValue(
          parseInt(event?.target?.value) <= 100
            ? event?.target?.value
            : counterValue
        );
      } else {
        setCounterValue(event?.target?.value);
      }
      onHandleChange(event?.target?.value);
    }
  };

  let step = 1;

  if (stepsOfTen) {
    step = 10;
  }

  const increamentCounter = () => {
    if (!(numberOfUsers !== 0 && numberOfUsers <= counterValue)) {
      if (labelName === "Numbers of users") {
        setCounterValue(
          parseFloat(counterValue) + step <= 100
            ? parseFloat(counterValue) + step
            : counterValue
        );
      } else {
        setCounterValue(parseFloat(counterValue) + step);
      }
      onHandleChange(parseFloat(counterValue) + step);
    }
  };

  const decreamentCounter = () => {
    if (counterValue - step >= min) {
      setCounterValue(parseFloat(counterValue) - step);
      onHandleChange(parseFloat(counterValue) - step);
    }
  };

  const RenderTooltip = () => {
    if (props.imageTooltip) {
      return (
        <ImageTooltip title={tooltipTitle} placement="top">
          <Info style={{ marginLeft: "7px", width: "16px", height: "16px" }} />
        </ImageTooltip>
      );
    } else if (isTooltip) {
      return (
        <LightTooltip title={tooltipTitle} placement="top">
          <Info style={{ marginLeft: "7px", width: "16px", height: "16px" }} />
        </LightTooltip>
      );
    } else {
      return;
    }
  };

  return (
    <FormControl variant="standard" sx={boxStyle}>
      <Stack className="planBox">
        {labelName && (
          <Typography
            htmlFor="bootstrap-input"
            sx={{
              color: "text.secondary",
              fontSize: "14px !important",
              fontWeight: "400 !important",
              fontStyle: "normal !important",
              lineHeight: "160% !important",
              display: "flex",
              fontFamily: "Heebo",
              alignItems: "center",
            }}
          >
            {labelName}
            {isInfoIcon && <RenderTooltip />}
          </Typography>
        )}
        {!isPlans ? (
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #7B83EB",
              display: "flex",
              width: "114px",
              mt: "11px",
              height: "44px",
            }}
          >
            <Button
              sx={{ maxWidth: "28px", minWidth: "28px", p: 0 }}
              onClick={decreamentCounter}
            >
              <Minus />
            </Button>
            <Box width={"80%"}>
              <BootstrapInput
                disabled={numberOfUsers !== 0 && numberOfUsers <= counterValue}
                refs={refInput}
                type={"number"}
                min={min}
                value={counterValue}
                onChange={handleOnChange}
                {...props}
              />
            </Box>
            <Button
              sx={{ maxWidth: "28px", minWidth: "28px", p: 0 }}
              onClick={increamentCounter}
            >
              <Plus />
            </Button>
          </Box>
        ) : (
          <Box display={"flex"} flexWrap={"wrap"} className="singlePlanBox">
            <Box
              onClick={() =>
                onHandleChange({
                  id: 1,
                  name: "Starter",
                  price: planAmountMap[props.country]?.starter || 0,
                })
              }
              sx={{
                borderRadius: "13px",
                border: "1px solid #7B83EB",
                display: "flex",
                flexDirection: "column",
                background: "#62D8B5",
                color: "white",
                px: "26px",
                py: "10px",
                mr: "10px",
                mt: "10px",
                cursor: "pointer",
                opacity: selectedPlans?.id !== 1 ? 0.5 : 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "160%",
                }}
              >
                Starter
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "160%",
                }}
              >
                {formatCurrencyAmount(
                  planAmountMap[props.country]?.starter,
                  currency
                )}{" "}
                /user
              </Typography>
            </Box>
            <Box
              onClick={() =>
                onHandleChange({
                  id: 2,
                  name: "Essential",
                  price: planAmountMap[props.country]?.essl || 0,
                })
              }
              sx={{
                borderRadius: "13px",
                border: "1px solid #7B83EB",
                display: "flex",
                flexDirection: "column",
                background: "#33388D",
                color: "white",
                px: "22px",
                py: "10px",
                mr: "10px",
                mt: "10px",
                cursor: "pointer",
                opacity: selectedPlans?.id !== 2 ? 0.5 : 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "160%",
                }}
              >
                Essential
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "160%",
                }}
              >
                {formatCurrencyAmount(
                  planAmountMap[props.country]?.essl,
                  currency
                )}{" "}
                /user
              </Typography>
            </Box>
            <Box
              onClick={() =>
                onHandleChange({
                  id: 3,
                  name: "Professional",
                  price: planAmountMap[props.country]?.pro || 0,
                })
              }
              sx={{
                borderRadius: "13px",
                border: "1px solid #7B83EB",
                display: "flex",
                flexDirection: "column",
                background: "#7B83EB",
                color: "white",
                px: "17px",
                py: "10px",
                mt: "10px",
                cursor: "pointer",
                opacity: selectedPlans?.id !== 3 ? 0.5 : 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "160%",
                }}
              >
                Professional
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "160%",
                }}
              >
                {formatCurrencyAmount(
                  planAmountMap[props.country]?.pro,
                  currency
                )}{" "}
                /user
              </Typography>
            </Box>
          </Box>
        )}
        {isAddition && (
          <Typography
            sx={{
              color: "#5E718B",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              opacity: "0.5",
              marginTop: "8px",
            }}
          >
            In blocks of 10 or 100
          </Typography>
        )}
      </Stack>
    </FormControl>
  );
};
export default CustomCounter;
