import { Box, Typography } from "@mui/material";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import styled from "@emotion/styled";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "180px",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: 12,
  },
}));
const ContractsPlans = ({ handleChange, signupdetails }) => (
  <Box display={"flex"} sx={{}} flexWrap={"wrap"} className="selectPlanBtn">
    <Box
      onClick={() => handleChange("selected_plan", 4)}
      sx={{
        display: "flex",
        borderRadius: "13px",
        border: "1px solid #7B83EB",
        background: signupdetails?.selected_plan === 4 ? "#8EE3F5" : "#FFF",
        color: signupdetails?.selected_plan === 4 ? "#FFF" : "#666",
        justifyContent: "center",
        alignItems: "center",
        py: "18px",
        px: "11px",
        cursor: "pointer",
        mt: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "160%",
        }}
      >
        No contract
        <Typography
        sx={{
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "160%",
          mx: "5px",
        }}
      >
        (Higher rates but <br /> ultimate flexibility)
      </Typography>
      </Typography>

      <LightTooltip
        title={"No lock in contract month to month agreement "}
        placement="top"
      >
        <Info style={{ marginLeft: "5px" }} />
      </LightTooltip>
    </Box>
    <Box
      onClick={() => handleChange("selected_plan", 3)}
      sx={{
        display: "flex",
        borderRadius: "13px",
        border: "1px solid #7B83EB",
        background: signupdetails?.selected_plan === 3 ? "#8EE3F5" : "#FFF",
        color: signupdetails?.selected_plan === 3 ? "#FFF" : "#666",
        justifyContent: "center",
        alignItems: "center",
        py: "18px",
        px: "11px",
        mx: "33px",
        cursor: "pointer",
        mt: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "160%",
          fontFamily: "Heebo",
          mr:"7px",
        }}
      >
        12
      </Typography>
      <Typography
        sx={{
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "160%",
          fontFamily: "Heebo",
          mx: "5px",
        }}
      >
        months
      </Typography>
    </Box>
    <Box
      onClick={() => handleChange("selected_plan", 2)}
      sx={{
        display: "flex",
        borderRadius: "13px",
        border: "1px solid #7B83EB",
        background: signupdetails?.selected_plan === 2 ? "#8EE3F5" : "#FFF",
        color: signupdetails?.selected_plan === 2 ? "#FFF" : "#666",
        justifyContent: "center",
        alignItems: "center",
        py: "18px",
        px: "11px",
        cursor: "pointer",
        mt: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "160%",
          mr:"7px",
        }}
      >
        24
      </Typography>
      <Typography
        sx={{
          fontSize: " 14px",
          fontStyle: "normal",
          fontFamily: "Heebo",
          fontWeight: "400",
          lineHeight: "160%",
          mx: "5px",
        }}
      >
        months
      </Typography>
    </Box>
    <Box
      onClick={() => handleChange("selected_plan", 1)}
      sx={{
        display: "flex",
        borderRadius: "13px",
        border: "1px solid #7B83EB",
        background: signupdetails?.selected_plan === 1 ? "#8EE3F5" : "#FFF",
        color: signupdetails?.selected_plan === 1 ? "#FFF" : "#666",
        justifyContent: "center",
        alignItems: "center",
        py: "18px",
        px: "11px",
        mx: "33px",
        cursor: "pointer",
        mt: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontStyle: "normal",
          fontFamily: "Heebo",
          fontWeight: "400",
          lineHeight: "160%",
          mr:"7px",
        }}
      >
        36
      </Typography>
      <Typography
        sx={{
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "160%",
          mx: "5px",
        }}
      >
        months
      </Typography>
    </Box>
  </Box>
);

export default ContractsPlans;
