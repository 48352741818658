import { Stack, Typography } from "@mui/material";
import CustomCounter from "../CustomCounter";
import React from "react";

const CallRecording = React.forwardRef(
  ({ handleChange, signupdetails, isFullScreen }, ref) => {
    return (
      <Stack
        ref={ref}
        sx={{
          mb: isFullScreen ? "20px" : "20px",
          mt: isFullScreen ? "20px" : "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Heebo",
            fontWeight: "600",
            color: "#4f555d",
            mb: "8px",
          }}
        >
          Call Recording
        </Typography>
        <CustomCounter
          labelName={"Basic call recording - How many users?"}
          isInfoIcon={true}
          min={0}
          value={signupdetails?.basic_call_user}
          onHandleChange={(value) => {
            handleChange("basic_call_user", value);
          }}
          tooltipTitle="Add-on call recording for your users."
          placeholder={0}
        />
        <CustomCounter
          boxStyle={{ marginTop: "34px" }}
          labelName={"Premium call recording - How many users?"}
          isInfoIcon={true}
          placeholder={0}
          min={0}
          tooltipTitle="Add-on call recording for your users."
          value={signupdetails?.premium_call_user}
          onHandleChange={(value) => {
            handleChange("premium_call_user", value);
          }}
        />
      </Stack>
    );
  }
);
export default CallRecording;
