import { createContext } from "react";

export const AppContext = createContext({
  currency: "",
  conversionRates: {},
  setCurrencyData: () => {},
  defaultConversionRates: [],
  setDefaultConversion: () => {},
  setCurrency: () => {},
});
