import { Box, Container, Grow, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintIcon from "../../assets/PrintIcon";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import LocationFullContainer from "../LocationFullContainer";
import SummaryPopup from "../SummaryPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const FullScreenDialog = ({
  open,
  isFullScreen,
  setFullScreen,
  isNumberUserErrorModal,
  handleClose,
  signupdetails,
  addNewLocation,
  handleLocation,
  removeLocation,
  handleModalClose,
}) => {
  const printable = useRef(null);

  return (
    <React.Fragment>
      <Dialog
        ref={printable}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{
          backgroundColor: "#EBF6FF",
          borderRadius: 20,
          backdropFilter: "blur(137px)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </Box>
        <Container
          display="flex"
          sx={{ height: "100%", flexDirection: "column" }}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <LocationFullContainer
            isFullScreen={open}
            setFullScreen={setFullScreen}
            isNumberUserErrorModal={isNumberUserErrorModal}
            handleModalClose={handleModalClose}
            signupdetails={signupdetails}
            addNewLocation={addNewLocation}
            handleLocation={handleLocation}
            removeLocation={removeLocation}
          />
          <ReactToPrint
            bodyClass="print-agreement"
            content={() => printable.current}
            trigger={() => (
              <IconButton className="btn-print" size="large">
                <PrintIcon />
              </IconButton>
            )}
          />
        </Container>
        {open && (
          <Box
            border={"1px solid rgb(123, 131, 235)"}
            borderRadius={"20px"}
            sx={{
              position: "absolute",
              top: "30%",
              right: { xs: "0", md: "50px" },
              p: "5px",
            }}
          >
            <SummaryPopup isFullScreen={open} isAddons={false} {...signupdetails} />
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default FullScreenDialog;
