import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../assets/svg/left-back.svg";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BgImage from "../../assets/jpg/bg.jpg";
import fetchData from "../../axios/fecthData";
import {
  addons,
  location,
  payments,
  signup,
  signupDetailsApi,
} from "../../axios/serverUrl";
import CustomFooter from "../../components/CustomFooter/CustomFooter";
import CustomStepper from "../../components/CustomStepper";
import Addons from "../../container/Addons";
import Location from "../../container/Location/Location";
import Started from "../../container/Started";
import Summary from "../../container/Summary/Summary";
import { AppContext } from "../../store/store";
import cartImage from "../../assets/png/cart.png";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import headsets from "../../assets/png/headset.png";
import headsets1 from "../../assets/png/headset1.png";
import headsets2 from "../../assets/png/headset2.png";
import headsets3 from "../../assets/png/headset3.png";
import ivrs from "../../assets/png/ivr.png";
import meetings from "../../assets/png/meeting.png";
import PersonIcons from "../../assets/png/Vector.png";
import {
  addonsBody,
  isValidEmail,
  labelList,
  locationBody,
  signupBody,
  signupDetailBody,
  signupNullDetails,
  totalPayment,
} from "../../utils/Constants";
import CheckoutSummary from "../CheckoutSummary";
import CartPopup from "./CartPopup";
import CustomButton from "../../components/CustomButton";

const Signup = () => {
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("is_checkout") === "true" ? 3 : 0
  );
  const [isSuccess, setSuccess] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isNumberUserErrorModal, setNumberUserErrorModal] = useState(false);
  const { currency } = useContext(AppContext);

  useEffect(() => {
    localStorage.setItem("is_checkout", "false");
  }, []);
  const [viewOneOff, setViewOneOff] = useState(true);

  const handleToggleView = (view) => {
    setViewOneOff(view);
  };
  const isAbove720 = useMediaQuery("(min-width:720px)");
  // Cart Items with quantities included
  const cartItems = {
    oneOff: [],
    monthly: [],
  };

  const [oneOffItems, setOneOffItems] = useState(cartItems.oneOff);
  const [monthlyItems, setMonthlyItems] = useState(cartItems.monthly);

  const handleIncrementOneOff = (index) => {
    const updatedItems = [...oneOffItems];
    if (updatedItems[index].name.includes("Headset")) {
      signupdetails.handset = signupdetails.handset + 1;
    } else if (updatedItems[index].name.includes("Handset1")) {
      signupdetails.handset1 = signupdetails.handset1 + 1;
    } else if (updatedItems[index].name.includes("Handset2")) {
      signupdetails.handset2 = signupdetails.handset2 + 1;
    } else if (updatedItems[index].name.includes("Handset3")) {
      signupdetails.handset3 = signupdetails.handset3 + 1;
    } else if (updatedItems[index].name.includes("IVR Call Flow Setup")) {
      signupdetails.total_ivr_flow = signupdetails.total_ivr_flow + 1;
    } else if (updatedItems[index].name.includes("Number Provisioning")) {
      signupdetails.meeting_device = signupdetails.meeting_device + 1;
    }
    updatedItems[index].quantity += 1;
    setOneOffItems(updatedItems);
  };

  const handleDecrementOneOff = (index) => {
    const updatedItems = [...oneOffItems];
    if (updatedItems[index].quantity > 1) {
      if (updatedItems[index].name.includes("Headset")) {
        signupdetails.handset = signupdetails.handset - 1;
      } else if (updatedItems[index].name.includes("Handset1")) {
        signupdetails.handset1 = signupdetails.handset1 - 1;
      } else if (updatedItems[index].name.includes("Handset2")) {
        signupdetails.handset2 = signupdetails.handset2 - 1;
      } else if (updatedItems[index].name.includes("Handset3")) {
        signupdetails.handset3 = signupdetails.handset3 - 1;
      } else if (updatedItems[index].name.includes("IVR Call Flow Setup")) {
        signupdetails.total_ivr_flow = signupdetails.total_ivr_flow - 1;
      } else if (updatedItems[index].name.includes("Number Provisioning")) {
        signupdetails.meeting_device = signupdetails.meeting_device - 1;
      }
      updatedItems[index].quantity -= 1;
      setOneOffItems(updatedItems);
    }
  };

  // Increment and decrement for monthly items
  const handleIncrementMonthly = (index) => {
    const updatedItems = [...monthlyItems];
    if (updatedItems[index].name.includes("Basic call")) {
      signupdetails.basic_call_user = signupdetails.basic_call_user + 1;
    } else if (updatedItems[index].name.includes("Premium call")) {
      signupdetails.premium_call_user = signupdetails.premium_call_user + 1;
    }
    updatedItems[index].quantity += 1;
    setMonthlyItems(updatedItems);
  };

  const handleDecrementMonthly = (index) => {
    const updatedItems = [...monthlyItems];
    if (updatedItems[index].quantity > 1) {
      if (updatedItems[index].name.includes("Basic call")) {
        signupdetails.basic_call_user = signupdetails.basic_call_user - 1;
      } else if (updatedItems[index].name.includes("Premium call")) {
        signupdetails.premium_call_user = signupdetails.premium_call_user - 1;
      }
      updatedItems[index].quantity -= 1;
      setMonthlyItems(updatedItems);
    }
  };

  // Remove item from one-off items
  const handleRemoveOneOff = (index) => {
    if (oneOffItems[index].name.includes("Headset")) {
      signupdetails.handset = 0;
    } else if (oneOffItems[index].name.includes("Handset1")) {
      signupdetails.handset1 = 0;
    } else if (oneOffItems[index].name.includes("Handset2")) {
      signupdetails.handset2 = 0;
    } else if (oneOffItems[index].name.includes("Handset3")) {
      signupdetails.handset3 = 0;
    } else if (oneOffItems[index].name.includes("IVR Call Flow Setup")) {
      signupdetails.total_ivr_flow = 0;
    } else if (oneOffItems[index].name.includes("Number Provisioning")) {
      signupdetails.meeting_device = 0;
    }
    const updatedItems = oneOffItems.filter((_, i) => i !== index);
    setOneOffItems(updatedItems);
  };

  // Remove item from monthly items
  const handleRemoveMonthly = (index) => {
    if (monthlyItems[index].name.includes("Basic call")) {
      signupdetails.basic_call_user = 0;
    } else if (monthlyItems[index].name.includes("Premium call")) {
      signupdetails.premium_call_user = 0;
    }
    const updatedItems = monthlyItems.filter((_, i) => i !== index);
    setMonthlyItems(updatedItems);
  };
  //
  useEffect(() => {
    if (currency !== "AUD") {
      resetAddonsAdditionalData();
    }
    setsignupdetails((prev) => {
      return { ...prev, selected_currency: currency };
    });
  }, [currency]);
  const [cartCount, setCartCount] = useState(0);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const toggleCartPopup = () => {
    setIsCartPopupOpen(!isCartPopupOpen);
  };
  const [phoneNumberDetails, setPhoneNumberDetails] = useState({
    fullNumber: "",
    isValid: false,
  });

  const [signupdetails, setsignupdetails] = useState(
    localStorage?.getItem("fabio_details")
      ? JSON.parse(localStorage?.getItem("fabio_details"))
      : signupNullDetails
  );
  const createItem = (name, price, quantity, image) => {
    return { name, description: "Lorem Ipsum", price, quantity, image };
  };
  const [productQuantities, setProductQuantities] = useState({
    basicCall: 0,
    premiumCall: 0,
    handset: 0,
    handset1: 0,
    handset2: 0,
    handset3: 0,
    ivr: 0,
    meeting: 0,
  });
  useEffect(() => {
    if (signupdetails?.location?.length) {
      let totalCount = 0;
      signupdetails.location.forEach((loc) => {
        totalCount +=
          loc.additional_numbers + loc.number_of_users + loc.numbers_to_port;
      });
      setCartCount(totalCount);

      // Update the product quantities state dynamically
      setProductQuantities((prev) => ({
        ...prev,
        basicCall: signupdetails.basic_call_user || 0,
        premiumCall: signupdetails.premium_call_user || 0,
        handset: signupdetails.handset || 0,
        handset1: signupdetails.handset1 || 0,
        handset2: signupdetails.handset2 || 0,
        handset3: signupdetails.handset3 || 0,
        ivr: signupdetails.total_ivr_flow || 0,
        meeting: signupdetails.meeting_device || 0,
      }));

      // Update monthlyItems and oneOffItems
      const updatedMonthlyItems = [];
      const updatedOneOffItems = [];

      // Helper function to add monthly items
      if (signupdetails.basic_call_user) {
        updatedMonthlyItems.push({
          name: "Basic call recording",
          description: "Lorem Ipsum",
          price: 180,
          icon: <PersonIcon />,
          quantity: signupdetails.basic_call_user,
        });
      }

      if (signupdetails.premium_call_user) {
        updatedMonthlyItems.push({
          name: "Premium call recording",
          description: "Lorem Ipsum",
          price: 170,
          icon: <PersonIcon />,
          quantity: signupdetails.premium_call_user,
        });
      }

      // Helper function to add one-off items (using createItem helper)
      const oneOffProducts = [
        {
          name: "Headset",
          price: 50,
          quantity: signupdetails.handset,
          image: headsets,
        },
        {
          name: "Handset1",
          price: 30,
          quantity: signupdetails.handset1,
          image: headsets1,
        },
        {
          name: "Handset2",
          price: 100,
          quantity: signupdetails.handset2,
          image: headsets2,
        },
        {
          name: "Handset3",
          price: 100,
          quantity: signupdetails.handset3,
          image: headsets3,
        },
        {
          name: "IVR Call Flow Setup",
          price: 20,
          quantity: signupdetails.total_ivr_flow,
          image: ivrs,
        },
        {
          name: "Number Provisioning",
          price: 15,
          quantity: signupdetails.meeting_device,
          image: meetings,
        },
      ];

      oneOffProducts.forEach((product) => {
        if (product.quantity) {
          updatedOneOffItems.push(
            createItem(
              product.name,
              product.price,
              product.quantity,
              product.image
            )
          );
        }
      });

      // Set the items to state
      setOneOffItems(updatedOneOffItems);
      setMonthlyItems(updatedMonthlyItems);
    }
  }, [signupdetails]);

  const navigate = useNavigate();

  const { search } = useLocation();

  const phoneNumberHandler = (details) => {
    setsignupdetails((prev) => {
      return {
        ...prev,
        phone: details.phoneNumber,
        full_phone_number: details.fullNumber,
      };
    });
    setPhoneNumberDetails({
      fullNumber: details.fullNumber,
      isValid: details.isValid,
    });
  };

  const sendPayment = async (temp) => {
    const data = await fetchData(
      "post",
      payments,
      { token: localStorage.getItem("token") },
      {
        user_uuid: localStorage.getItem("user_id"),
        charge_id: temp[0],
        payment_status: temp[2],
        client_secret: temp[1],
      }
    );
    if (data?.data) {
      setActiveStep(4);
      if (temp[2] === "succeeded") {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      navigate("/");
    }
  };
  if (search.replace("?", "").split("&")) {
    const temp = [];
    search.split("&").map((item) => {
      if (item.split("=")[1]) {
        temp.push(item.split("=")[1]);
      }
      return false;
    });
    if (temp.length > 1) {
      sendPayment(temp);
    }
  }
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }
  useEffect(() => {
    if (search?.includes("utm")) {
      const urlParams = new URLSearchParams(search?.replace("?", ""));
      const entries = urlParams.entries();
      const params = paramsToObject(entries);
      localStorage.setItem("utm_data", JSON.stringify(params));
    }
  }, [search]);

  const handleNext = () => {
    localStorage.setItem("is_checkout", "false");
    localStorage.removeItem("fabio_details");
    localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    localStorage.setItem("is_checkout", "false");
    onSubmit(true);
  };

  const handleChange = (key, value) => {
    if (key === "country_code") {
      setsignupdetails((prev) => {
        return { ...prev, [key]: value.code, location_name: value.name };
      });
    } else if (key === "ivr_flow_value") {
      setsignupdetails({
        ...signupdetails,
        [key]: value,
        set_up_value:
          value === "curated"
            ? signupdetails?.selected_plan === "36 months"
              ? "$0.00"
              : signupdetails?.selected_plan === "24 months"
              ? "$247.5"
              : "$495"
            : "$0.00",
      });
    } else if (key === "selected_plan") {
      setsignupdetails({
        ...signupdetails,
        [key]: value,
        set_up_value:
          signupdetails?.ivr_flow_value === "curated"
            ? value === "36 months"
              ? "$0.00"
              : value === "24 months"
              ? "$247.5"
              : "$495"
            : "$0.00",
      });
    } else {
      setsignupdetails({ ...signupdetails, [key]: value });
      if (key === "ivr_flow" && value === false) {
        setsignupdetails((prev) => {
          return { ...prev, total_ivr_flow: 0 };
        });
      }
    }
  };
  const removeLocation = (index) => {
    setsignupdetails({
      ...signupdetails,
      location: signupdetails?.location?.filter(
        (val, index1) => index1 !== index + 1
      ),
    });
  };

  const handleLocation = (index, key, value) => {
    if (key === "is_selected_country") {
      setsignupdetails((prev) => {
        return {
          ...prev,
          location: prev?.location?.map((val, index1) =>
            index1 === index ? { ...val, country_code: value.code } : val
          ),
        };
      });
      if (value.code !== "AU") {
        resetAddonsAdditionalData();
      }
    }
    // if toll free numbers are not required set toll free quantity to zero
    else if (key === "is_toll_free_required" && value === false) {
      setsignupdetails((prev) => {
        const newLocation = prev?.location?.map((val, index1) =>
          index1 === index
            ? { ...val, [key]: value, total_toll_free_numbers: 1 }
            : val
        );
        return { ...prev, location: newLocation };
      });
    } else {
      setsignupdetails((prev) => {
        const newLocation = prev?.location?.map((val, index1) =>
          index1 === index ? { ...val, [key]: value } : val
        );
        return { ...prev, location: newLocation };
      });
    }
  };

  const addNewLocation = () => {
    setsignupdetails({
      ...signupdetails,
      location: [
        ...signupdetails.location,
        {
          country_code: "",
          location_name: "",
          user_id: 0,
          number_of_users: 1,
          numbers_to_port: 0,
          additional_numbers: 0,
          plan: {
            id: 1,
            name: "Starter",
            price: "36",
          },
          is_toll_free_required: false,
          total_toll_free_numbers: 1,
          is_primary: true,
        },
      ],
    });
    resetAddonsAdditionalData();
  };

  const onSubmit = async (isBack = false) => {
    // return handleNext();
    if (
      !signupdetails.email ||
      !signupdetails.first_name ||
      !signupdetails.last_name ||
      !signupdetails.phone ||
      !signupdetails.company_name
    ) {
      toast("All fields required!");
    } else {
      if (activeStep === 0) {
        if (isValidEmail(signupdetails?.email)) {
          if (phoneNumberDetails.isValid) {
            sendApi(signup, signupBody(signupdetails), activeStep, isBack);
          } else {
            toast("Please enter a valid phone number!");
          }
        } else {
          toast("Email is invalid!");
        }
      } else if (activeStep === 1) {
        if (isBack) {
          sendApi(
            location,
            locationBody(signupdetails, activeStep),
            activeStep,
            isBack
          );
        } else {
          let isNextStep = true;
          if (!isNumberUserErrorModal) {
            signupdetails.location.forEach((element) => {
              if (!isNextStep) return;

              if (
                element.additional_numbers + element.numbers_to_port <
                element.number_of_users
              ) {
                setNumberUserErrorModal(true);
                isNextStep = false;
              } else {
                isNextStep = true;
              }
            });
          }
          if (isNextStep && !isBack) {
            sendApi(location, locationBody(signupdetails), activeStep, isBack);
          }
        }
      } else if (activeStep === 2) {
        sendApi(addons, addonsBody(signupdetails), activeStep, isBack);
      } else if (activeStep === 3) {
        if (isBack) {
          const totalAmount = totalPayment(signupdetails, activeStep, isBack);
          localStorage.setItem("amount", totalAmount);
          localStorage.setItem("currency", currency);
          const utm_data = localStorage.getItem("utm_data");
          const body = signupDetailBody(signupdetails, utm_data);
          localStorage.setItem("fabio_details_address", JSON.stringify(body));
          sendApi(signupDetailsApi, body, activeStep, isBack);
        } else {
          if (
            !signupdetails.business_reg_no ||
            !signupdetails.address ||
            !signupdetails.city ||
            !signupdetails.post_code ||
            !signupdetails.region
          ) {
            toast("All fields required!");
          } else if (
            signupdetails.shipping === "Multiple Addresses" &&
            (!signupdetails.shipping_address ||
              !signupdetails.shipping_city ||
              !signupdetails.shipping_post_code ||
              !signupdetails.shipping_region)
          ) {
            toast("All fields required!");
          } else {
            let isNextStep = true;
            const totalAmount = totalPayment(signupdetails, activeStep);
            localStorage.setItem("amount", totalAmount);
            localStorage.setItem("currency", currency);
            const utm_data = localStorage.getItem("utm_data");
            const body = signupDetailBody(signupdetails, utm_data);
            localStorage.setItem("fabio_details_address", JSON.stringify(body));
            sendApi(signupDetailsApi, body, activeStep, isBack);
            if (!isNextStep) return;
          }
        }
      } else if (activeStep === 4) {
        if (isBack) {
          sendApi(
            location,
            locationBody(signupdetails, activeStep),
            activeStep,
            isBack
          );
        } else {
          sendApi(addons, addonsBody(signupdetails), activeStep, isBack);
        }
      }
    }
  };

  const sendApi = async (endpoint, body, activeStep, isBack = false) => {
    const data = await fetchData(
      "post",
      endpoint,
      { token: localStorage.getItem("token") },
      body
    );

    if (data.data) {
      if (isBack) {
        if (activeStep === 0) {
          localStorage.setItem("user_id", data.data.uuid);
          localStorage.setItem("token", data.data.token);
        }
        if (activeStep === 3) {
          localStorage.removeItem("fabio_details");
          localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        if (activeStep === 0) {
          localStorage.setItem("user_id", data.data.uuid);
          localStorage.setItem("token", data.data.token);
        }
        if (activeStep === 3) {
          localStorage.removeItem("fabio_details");
          localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
        }
        if (activeStep === 4) {
          localStorage.setItem("is_checkout", "true");
          // {[...monthlyItems, ...oneOffItems].reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
          const sanitizedMonthlyItems = monthlyItems.map((item) => {
            const { icon, ...rest } = item;
            return rest;
          });

          const sanitizedOneOffItems = oneOffItems.map((item) => {
            const { image, ...rest } = item;
            return rest;
          });

          localStorage.setItem(
            "total_amount",
            JSON.stringify([...sanitizedMonthlyItems, ...sanitizedOneOffItems])
          );
          navigate("/checkout");
        } else {
          handleNext();
        }
      }
    } else {
      toast(data.message);
    }
  };

  const [isTransitionActive, setIsTransitionActive] = useState(false);

  const resetAddonsAdditionalData = () => {
    setsignupdetails((prev) => {
      return {
        ...prev,
        basic_call_recording: 0,
        basic_call_user: 0,
        premium_call_recording: 0,
        premium_call_user: 0,
        handset: 0,
        headset1: 0,
        headset2: 0,
        headset3: 0,
        meeting_device: 0,
      };
    });
  };

  const QuantityControl = ({ quantity, onDecrement, onIncrement }) => (
    <Box
    display="flex"
    alignItems="center"
    // justifyContent={isAbove720 ? "center" : "flex-end"} // Align to end if isAbove720 is false
    border="1px solid #7B83EB"
    borderRadius="4px"
    width="fit-content"
    ml={isAbove720 ? 0 : 2} 
    >
      <Button
        variant="text"
        onClick={onDecrement}
        sx={{
          minWidth: "30px",
          padding: "4px 8px",
          borderRadius: "0",
          borderRight: "1px solid #7B83EB",
        }}
      >
        -
      </Button>
      <Typography
        variant="body1"
        sx={{
          minWidth: "30px",
          textAlign: "center",
          padding: "4px 8px",
          borderRight: "1px solid #7B83EB",
        }}
      >
        {quantity}
      </Typography>
      <Button
        variant="text"
        onClick={onIncrement}
        sx={{ minWidth: "30px", padding: "4px 8px", borderRadius: "0" }}
      >
        +
      </Button>
    </Box>
  );

  const taxRate = 0.15; // Example tax rate of 15%
  const couponDiscount = 0; // Example coupon discount

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        className={"main"}
        style={{
          backgroundPositionY: "center",
          backgroundPositionX: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BgImage})`,
        }}
        sx={{
          pl: { xs: "12px", md: "60px" },
          pr: { xs: "12px", md: "69px" },
          pb: { xs: "10px", md: "0px" },
          // py: { xs: "10px", md: 5 },
          // minHeight: '100vh',
          height: { xs: "auto", md: "100vh" },
        }}
      >
        <Box
          display="flex"
          sx={{ flexDirection: { md: "row", xs: "column" } }}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            width={{ md: "25%", xs: "100%" }}
            sx={{ display: { md: "flex", xs: "flex" } }}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <CustomStepper activeStep={activeStep} />
          </Box>
          <Box
            display={"flex"}
            width={"75%"}
            height={"89%"}
            sx={{ width: { md: "75%", xs: "100%" } }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className="details-form-container"
              width={"100%"}
              style={{
                backgroundColor: "#EBF6FF",
                borderRadius: 20,
                backdropFilter: "blur(137px)",
              }}
              sx={{
                pl: { md: "40px", xs: "20px" },
                pr: { md: "40px", xs: "10px" },
                pt: { md: "40px", xs: "30px" },
                pb: { md: "40px", xs: "30px" },
                overflow: { md: "hidden", xs: "auto" },
              }}
            >
              {/* <form style={{ width: "100%", height: "100%" }}> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "20px 20px 20px 0",
                }}
              >
                <Typography
                  variant="h1"
                  color="secondary"
                  sx={{
                    fontSize: { md: "55px", sx: "25px" },
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  {labelList[activeStep || 0]?.label}
                </Typography>
                {activeStep !== 4 ? (
                  <div style={{ position: "relative" }}>
                    <img
                      src={cartImage}
                      alt="Cart Icon"
                      style={{
                        width: "35px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                      onClick={toggleCartPopup}
                    />
                    {cartCount > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "50%",
                          width: "22px",
                          height: "22px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {cartCount}
                      </div>
                    )}
                  </div>
                ) : null}
                {isCartPopupOpen && (
                  <CartPopup
                    isOpen={isCartPopupOpen}
                    onClose={toggleCartPopup}
                    signupdetails={signupdetails}
                    handleIncrementOneOff={handleIncrementOneOff}
                    oneOffItemss={oneOffItems}
                    monthlyItemss={monthlyItems}
                    handleDecrementOneOff={handleDecrementOneOff}
                    handleIncrementMonthly={handleIncrementMonthly}
                    handleRemoveOneOff={handleRemoveOneOff}
                    handleRemoveMonthly={handleRemoveMonthly}
                    activeStep={activeStep}
                  />
                )}
              </div>

              <Box
                className="details-form-wrapper"
                sx={{
                  mt: {
                    xs: "30px",
                    md: activeStep !== 2 && activeStep !== 1 ? "30px" : "10px",
                  },
                  overflow: isTransitionActive ? "hidden" : "auto",
                  height: {
                    lg:
                      activeStep === 1
                        ? "72% !important"
                        : activeStep === 3
                        ? "70% !important"
                        : "65%",
                    md:
                      activeStep !== 2
                        ? activeStep === 0
                          ? "70%"
                          : "80%"
                        : "80%",
                  },
                }}
              >
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 0}
                >
                  <Started
                    onPhoneNumberChange={phoneNumberHandler}
                    handleChange={handleChange}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 1}
                >
                  <Location
                    isNumberUserErrorModal={isNumberUserErrorModal}
                    handleModalClose={() => setNumberUserErrorModal(false)}
                    setFullScreen={() => setFullScreen(!isFullScreen)}
                    isFullScreen={isFullScreen}
                    handleLocation={handleLocation}
                    handleChange={handleChange}
                    removeLocation={removeLocation}
                    addNewLocation={addNewLocation}
                    signupdetails={signupdetails}
                    handleNext={() => {
                      setNumberUserErrorModal(false);
                      sendApi(
                        location,
                        locationBody(signupdetails, activeStep),
                        activeStep,
                        false
                      );
                    }}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 2}
                >
                  <Addons
                    handleChange={handleChange}
                    setFullScreen={() => setFullScreen(!isFullScreen)}
                    isFullScreen={isFullScreen}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 3}
                >
                  <Summary
                    handleChange={handleChange}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 4}
                >
                  <>
                    <Box sx={{ mt: "33px" }} fontFamily="Heebo">
                      {/* Cart Items Section */}
                      <Grid container spacing={4}>
                        {/* One-off Cost Items */}
                        {!isAbove720 && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mb: 2,
                              pedding: "10px",
                              width: "100%",
                              Height: "38px",
                              boxShadow: "none",
                            }}
                          >
                            <Button
                              variant={viewOneOff ? "contained" : "outlined"}
                              onClick={() => handleToggleView(true)}
                              className="both-mobile-btn"
                              sx={{
                                mr: 0,
                                borderRadius: "20px 0 0 20px",
                                backgroundColor: viewOneOff
                                  ? "#002855"
                                  : "transparent",
                                color: viewOneOff ? "#fff" : "#002855",
                                borderColor: "#002855",
                                textTransform: "none",
                                fontWeight: "500",
                                padding: "0",
                                minHeight: "38px",
                                fontSize: "16px",
                                fontFamily: "Heebo",
                                lineHeight: "17.5px",
                                "&:hover": {
                                  backgroundColor: viewOneOff
                                    ? "#002855"
                                    : "rgba(0, 40, 85, 0.1)",
                                },
                              }}
                            >
                              One-off Cost Items
                            </Button>
                            <Button
                              variant={!viewOneOff ? "contained" : "outlined"}
                              onClick={() => handleToggleView(false)}
                              sx={{
                                ml: 0,
                                borderRadius: "0 20px 20px 0",
                                backgroundColor: !viewOneOff
                                  ? "#002855"
                                  : "transparent",
                                color: !viewOneOff ? "#fff" : "#002855",
                                borderColor: "#002855",
                                textTransform: "none",
                                fontFamily: "Heebo",
                                minHeight: "38px",
                                padding: "0",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "17.5px",
                                "&:hover": {
                                  backgroundColor: !viewOneOff
                                    ? "#002855"
                                    : "rgba(0, 40, 85, 0.1)",
                                },
                              }}
                            >
                              Monthly Cost Items
                            </Button>
                          </Box>
                        )}
                        {viewOneOff ? (
                          <Grid
                            item
                            xs={6}
                            sx={{
                              border: isAbove720
                                ? "1px solid #7B83EB80"
                                : "none",
                              borderRadius: "5px",
                              padding: "20px",
                            }}
                          >
                            {isAbove720 && (
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                fontSize="20px"
                                className="font-dm"
                                color="#0C2A52"
                                gutterBottom
                              >
                                One-off Cost Items
                              </Typography>
                            )}
                            {isAbove720 && <Divider sx={{ mb: 2 }} />}
                            <Box
                              sx={{
                                height: "48vh",
                                maxHeight: "48vh",
                                overflowY: "auto",
                              }}
                            >
                              {oneOffItems.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    border: isAbove720
                                      ? "none"
                                      : "1px solid #7B83EB80",
                                    // marginBottom: isAbove720 ? "none" : "10px",
                                    borderRadius: "5px",
                                    padding: isAbove720
                                      ? "none"
                                      : "10px 0px 10px 10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      // mb: 2,
                                    }}
                                  >
                                    <img
                                      src={item.image}
                                      alt={item.name}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                    <Box sx={{ flex: 1, ml: 2 }}>
                                      <Typography
                                        fontWeight="700"
                                        className="item-name"
                                      >
                                        {item.name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        className="item-desc"
                                      >
                                        {item.description}
                                      </Typography>
                                      {!isAbove720 && viewOneOff && (
                                        <Typography
                                          fontWeight="600"
                                          className="item-price-mobile"
                                        >
                                          ${item.price * item.quantity}
                                        </Typography>
                                      )}
                                    </Box>

                                    {isAbove720 ? (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        border="1px solid #7B83EB"
                                        borderRadius="4px"
                                        width="fit-content"
                                        marginRight="10px"
                                      >
                                        <Button
                                          variant="text"
                                          onClick={() =>
                                            handleDecrementOneOff(index)
                                          }
                                          sx={{
                                            minWidth: "30px",
                                            padding: "4px 8px",
                                            borderRadius: "0",
                                            borderRight: "1px solid #7B83EB",
                                          }}
                                        >
                                          -
                                        </Button>
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            minWidth: "30px",
                                            textAlign: "center",
                                            padding: "4px 8px",
                                            borderRight: "1px solid #7B83EB",
                                          }}
                                        >
                                          {item.quantity}
                                        </Typography>
                                        <Button
                                          variant="text"
                                          onClick={() =>
                                            handleIncrementOneOff(index)
                                          }
                                          sx={{
                                            minWidth: "30px",
                                            padding: "4px 8px",
                                            borderRadius: "0",
                                          }}
                                        >
                                          +
                                        </Button>
                                      </Box>
                                    ) : null}

                                    {isAbove720 ? (
                                      <>
                                        <Typography fontWeight="600">
                                          ${item.price * item.quantity}
                                        </Typography>
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveOneOff(index)
                                          }
                                        >
                                          <CloseIcon
                                            sx={{
                                              fontSize: 25,
                                              color: "#7B83EB",
                                              border: "2px solid #7B83EB",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                      </>
                                    ) : viewOneOff ? (
                                      <Box
                                        sx={{
                                          flex: 0,
                                          mr: 2,
                                          textAlign: "end",
                                        }}
                                      >
                                        <IconButton
                                          sx={{
                                            marginBottom: "20px",
                                            padding: "0px",
                                          }}
                                          onClick={() =>
                                            handleRemoveOneOff(index)
                                          }
                                        >
                                          <CloseIcon
                                            sx={{
                                              fontSize: 15,
                                              color: "#7B83EB",
                                              border: "2px solid #7B83EB",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                        <QuantityControl
                                          quantity={item.quantity}
                                          onDecrement={() =>
                                            handleDecrementOneOff(index)
                                          }
                                          onIncrement={() =>
                                            handleIncrementOneOff(index)
                                          }
                                        />
                                      </Box>
                                    ) : null}
                                  </Box>

                                  {isAbove720 &&
                                    index < oneOffItems.length - 1 && (
                                      <Divider sx={{ my: 2 }} />
                                    )}
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        ) : null}

                        {/* Monthly Cost Items */}
                        {!viewOneOff ? (
                          <Grid
                            item
                            xs={6}
                            sx={{
                              border: isAbove720
                                ? "1px solid #7B83EB80"
                                : "none",
                              borderRadius: "5px",
                              padding: "20px",
                            }}
                          >
                            {/* <Typography
                              variant="h6"
                              fontWeight="600"
                              fontSize="20px"
                              color="#0C2A52"
                              gutterBottom
                            >
                              Monthly Cost Items
                            </Typography> */}
                            {isAbove720 && <Divider sx={{ mb: 2 }} />}
                            <Box
                              sx={{
                                height: "48vh",
                                maxHeight: "48vh",
                                overflowY: "auto",
                              }}
                            >
                              {monthlyItems.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    border: isAbove720
                                      ? "none"
                                      : "1px solid #7B83EB80",
                                    marginBottom: isAbove720 ? "none" : "10px",
                                    borderRadius: "5px",
                                    padding: "10px 0px 10px 10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      // mb: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={PersonIcons}
                                        alt={item.name}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                      <Box sx={{ ml: 5 }}>
                                        <Typography
                                          fontWeight="700"
                                          className="item-name"
                                        >
                                          {item.name}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          className="item-desc"
                                        >
                                          {item.description}
                                        </Typography>
                                        {isAbove720 ? (
                                          <QuantityControl
                                            quantity={item.quantity}
                                            onDecrement={() =>
                                              handleDecrementMonthly(index)
                                            }
                                            onIncrement={() =>
                                              handleIncrementMonthly(index)
                                            }
                                          />
                                        ) : !viewOneOff ? (
                                          <Typography
                                            fontWeight="600"
                                            className="tem-price-mobile"
                                          >
                                            ${item.price * item.quantity}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                    </Box>

                                    {isAbove720 ? (
                                      <Box sx={{ ml: 2, textAlign: "center" }}>
                                        <Typography fontWeight="600">
                                          ${item.price * item.quantity}
                                        </Typography>
                                      </Box>
                                    ) : !viewOneOff ? (
                                      <Box
                                        sx={{
                                          flex: 0,
                                          mr: 2,
                                          textAlign: "end",
                                        }}
                                      >
                                        <IconButton
                                          sx={{
                                            marginBottom: "20px",
                                            padding: "0px",
                                          }}
                                          onClick={() =>
                                            handleRemoveMonthly(index)
                                          }
                                        >
                                          <CloseIcon
                                            sx={{
                                              fontSize: 15,
                                              color: "#7B83EB",
                                              border: "2px solid #7B83EB",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                        <QuantityControl
                                          quantity={item.quantity}
                                          onDecrement={() =>
                                            handleDecrementMonthly(index)
                                          }
                                          onIncrement={() =>
                                            handleIncrementMonthly(index)
                                          }
                                        />
                                      </Box>
                                    ) : null}
                                  </Box>
                                  {isAbove720 &&
                                    index < monthlyItems.length - 1 && (
                                      <Divider sx={{ my: 2 }} />
                                    )}
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        ) : null}
                        {/* Monthly Cost Items Desktop */}
                        {isAbove720 && (
                          <Grid
                            item
                            xs={6}
                            sx={{
                              border: isAbove720
                                ? "1px solid #7B83EB80"
                                : "none",
                              borderRadius: "5px",
                              padding: "20px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              fontSize="20px"
                              color="#0C2A52"
                              gutterBottom
                              className="font-dm"
                            >
                              Monthly Cost Items
                            </Typography>
                            {isAbove720 && <Divider sx={{ mb: 2 }} />}
                            <Box
                              sx={{
                                height: "48vh",
                                maxHeight: "48vh", // Set maximum height
                                overflowY: "auto", // Enable vertical scrolling
                              }}
                            >
                              {monthlyItems.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    border: isAbove720
                                      ? "none"
                                      : "1px solid #7B83EB80",
                                    marginBottom: isAbove720 ? "none" : "10px",
                                    borderRadius: "5px",
                                    padding: "20px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      mb: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={PersonIcons}
                                          alt={item.name}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </Box>
                                      <Box sx={{ ml: 5 }}>
                                        <Typography fontWeight="500">
                                          {item.name}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          className="item-desc"
                                        >
                                          {item.description}
                                        </Typography>
                                        {isAbove720 ? (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            border="1px solid #7B83EB"
                                            borderRadius="4px"
                                            width="fit-content"
                                            marginTop="5px"
                                          >
                                            <Button
                                              variant="text"
                                              onClick={() =>
                                                handleDecrementMonthly(index)
                                              }
                                              sx={{
                                                minWidth: "30px",
                                                padding: "4px 8px",
                                                borderRadius: "0",
                                                borderRight:
                                                  "1px solid #7B83EB",
                                              }}
                                            >
                                              -
                                            </Button>
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                minWidth: "30px",
                                                textAlign: "center",
                                                padding: "4px 8px",
                                                borderRight:
                                                  "1px solid #7B83EB",
                                              }}
                                            >
                                              {item.quantity}
                                            </Typography>
                                            <Button
                                              variant="text"
                                              onClick={() =>
                                                handleIncrementMonthly(index)
                                              }
                                              sx={{
                                                minWidth: "30px",
                                                padding: "4px 8px",
                                                borderRadius: "0",
                                              }}
                                            >
                                              +
                                            </Button>
                                          </Box>
                                        ) : (
                                          <Typography fontWeight="600">
                                            $ {item.price * item.quantity}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                    {isAbove720 ? (
                                      ""
                                    ) : (
                                      <Box sx={{ ml: 2, textAlign: "center" }}>
                                        <IconButton
                                          sx={{ marginBottom: "15px" }}
                                          onClick={() =>
                                            handleRemoveMonthly(index)
                                          }
                                        >
                                          <CloseIcon
                                            sx={{
                                              fontSize: 25,
                                              color: "#7B83EB",
                                              border: "2px solid #7B83EB",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          border="1px solid #7B83EB"
                                          borderRadius="4px"
                                          width="fit-content"
                                          marginTop="5px"
                                        >
                                          <Button
                                            variant="text"
                                            onClick={() =>
                                              handleDecrementMonthly(index)
                                            }
                                            sx={{
                                              minWidth: "30px",
                                              padding: "4px 8px",
                                              borderRadius: "0",
                                              borderRight: "1px solid #7B83EB",
                                            }}
                                          >
                                            -
                                          </Button>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              minWidth: "30px",
                                              textAlign: "center",
                                              padding: "4px 8px",
                                              borderRight: "1px solid #7B83EB",
                                            }}
                                          >
                                            {item.quantity}
                                          </Typography>
                                          <Button
                                            variant="text"
                                            onClick={() =>
                                              handleIncrementMonthly(index)
                                            }
                                            sx={{
                                              minWidth: "30px",
                                              padding: "4px 8px",
                                              borderRadius: "0",
                                            }}
                                          >
                                            +
                                          </Button>
                                        </Box>
                                      </Box>
                                    )}
                                    {isAbove720 ? (
                                      <>
                                        <Box
                                          sx={{ ml: 2, textAlign: "center" }}
                                        >
                                          <IconButton
                                            onClick={() =>
                                              handleRemoveMonthly(index)
                                            }
                                          >
                                            <CloseIcon
                                              sx={{
                                                fontSize: 25,
                                                color: "#7B83EB",
                                                marginBottom: "10px",
                                                border: "2px solid #7B83EB",
                                                borderRadius: "5px",
                                              }}
                                            />
                                          </IconButton>
                                          <Typography fontWeight="600">
                                            $ {item.price * item.quantity}
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  {isAbove720 &&
                                    index < monthlyItems.length - 1 && (
                                      <Divider sx={{ my: 2 }} />
                                    )}
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        )}
                      </Grid>

                      {/* Footer Section */}
                      <Grid container spacing={4} className="cus-calculation">
                        {/* Left Black Space */}
                        <Grid
                          item
                          xs={6}
                          sx={{
                            border: isAbove720 ? "1px solid #7B83EB80" : "none",
                            borderRadius: "5px",
                            padding: "20px",
                          }}
                          className="calculation-box-overwrite"
                        />

                        <Grid
                          item
                          xs={6}
                          className="calculation-box"
                          sx={{
                            border: isAbove720 ? "1px solid #7B83EB80" : "none",
                            borderRadius: "5px",
                            padding: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "right",
                              padding: isAbove720 ? "12px" : "0px",
                            }}
                          >
                            {/* Sub Total Calculation */}
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mb={1}
                              color="#666666"
                            >
                              <Typography className="font-hebo">
                                Sub Total:
                              </Typography>
                              <Typography
                                sx={{ marginRight: "15px" }}
                                className="font-hebo"
                              >
                                $
                                {[...monthlyItems, ...oneOffItems]
                                  .reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  )
                                  .toFixed(2)}
                              </Typography>
                            </Box>

                            {/* Taxes Calculation */}
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mb={1}
                              color="#666666"
                            >
                              <Typography fontFamily="Heebo">Taxes:</Typography>
                              <Typography
                                sx={{ marginRight: "15px" }}
                                fontFamily="Heebo"
                              >
                                $
                                {(
                                  [...monthlyItems, ...oneOffItems].reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  ) * taxRate
                                ).toFixed(2)}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              color="#666666"
                              // mt={2}
                            >
                              <Typography fontFamily="Heebo">
                                Grand Total:
                              </Typography>
                              <Typography
                                sx={{ marginRight: "15px" }}
                                fontFamily="Heebo"
                                fontWeight="700"
                              >
                                $
                                {(
                                  [...monthlyItems, ...oneOffItems].reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  ) +
                                  [...monthlyItems, ...oneOffItems].reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  ) *
                                    taxRate -
                                  couponDiscount
                                ).toFixed(2)}
                              </Typography>
                            </Box>

                            {isAbove720 && (
                              <Box mt={2}>
                                <Button
                                  variant="outlined"
                                  sx={{ mr: 2, borderRadius: "9999px" }}
                                  onClick={handleBack}
                                >
                               <BackArrow />
                               Back
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    marginRight: "15px",
                                    borderRadius: "9999px",
                                  }}
                                  onClick={() => onSubmit(false)}
                                >
                                  Check out
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Grid>

                        {!isAbove720 && (
                          <Grid
                            item
                            xs={6}
                            className="calculation-box calculation-box-subbox"
                            sx={{
                              border: { sm: "1px solid #7B83EB80", xs: "none" },
                              borderRadius: "10px",
                              padding: "20px",
                              backgroundColor: "#f0f7ff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontFamily="Heebo"
                                color="#666666"
                                sx={{ fontSize: "14px" }}
                              >
                                Total Price: $
                                {(
                                  [...monthlyItems, ...oneOffItems].reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  ) +
                                  [...monthlyItems, ...oneOffItems].reduce(
                                    (total, item) =>
                                      total + item.price * item.quantity,
                                    0
                                  ) *
                                    taxRate -
                                  couponDiscount
                                ).toFixed(2)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="primary"
                                fontFamily="Heebo"
                              >
                                You Save: $5
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flex: 1,
                                mt: 2,
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  mr: 1,
                                  borderRadius: "14.5px",
                                  padding: "10px",
                                  borderColor: "#7B83EB80",
                                  color: "#7B83EB",
                                  maxWidth: "83px",
                                  "&:hover": {
                                    borderColor: "#7B83EB",
                                    backgroundColor: "#eef1ff",
                                  },
                                  textTransform: "none",
                                  fontFamily: "Heebo",
                                }}
                                className="font-700"
                                onClick={handleBack}
                              >
                                <BackArrow />
                                Back
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  borderRadius: "14.5px",
                                  padding: "10px 16px",
                                  backgroundColor: "#7B83EB",
                                  "&:hover": { backgroundColor: "#5a65e6" },
                                  lineHeight: "22.4px",
                                  textAlign: "center",
                                  minWidth: "108px",
                                  fontFamily: "Heebo",
                                }}
                                class="checkout-button-class"
                                onClick={() => onSubmit(false)}
                              >
                                Check out
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </>
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 5}
                >
                  <CheckoutSummary isSuccess={isSuccess} />
                </CSSTransition>
              </Box>
              {activeStep !== 4 && (
                <CustomFooter
                  activeStep={activeStep}
                  handleChange={handleChange}
                  handleBack={handleBack}
                  signupdetails={signupdetails}
                  onSubmit={() => onSubmit(false)}
                />
              )}
              {/* </form> */}
            </Box>
          </Box>
        </Box>
        {/* {false && (
          <Box
            className="summary-popup"
            sx={{
              display: { xs: "none", md: "flex" },
              position: "absolute",
              transform: "translateY(-50%)",
              top: activeStep === 1 ? "407px" : "45%",
              right: { xs: "0", md: "12px" },
            }}
          >
            <SummaryPopup
              isAddons={activeStep === 2 ? true : false}
              {...signupdetails}
            />
          </Box>
        )} */}
      </Box>
      <Toaster
        position={"top-right"}
        toastOptions={{
          style: {
            background: "#ee2400",
            color: "#fff",
          },
        }}
      />
    </React.Fragment>
  );
};
export default Signup;
