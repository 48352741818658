import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    ochre: {
      main: "#7B83EB",
      darkBlue: "#33388D",
      green: "#62D8B5",
      darkGreen: "#009688",
      lightGreen: "#E4F2F1",
      labelText: "#5E718B",
    },
    primary: {
      main: "#7B83EB",
      secondary: "#33388D",
    },
    secondary: {
      main: "#0C2A52",
    },
    text: {
      primary: "#33388D",
      secondary: "#5E718B",
      light: "#7B83EB",
    },
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: "Heebo",
        },
      },
    },
    typography: {
      fontFamily: ['"Heebo"'],
      body1: {
        fontFamily: "'Heebo'",
      },
    },
  },
});
export default theme;
