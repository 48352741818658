import { Stack } from "@mui/material";
import React, { useState } from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import FullScreenDialog from "../../components/FullScreenModal/FullScreenModal";
import LocationFullContainer from "../../components/LocationFullContainer";
import CustomModalUser from "../../components/CustomModalUser";

const Location = ({
  isFullScreen,
  setFullScreen,
  isNumberUserErrorModal = "false",
  handleModalClose,
  signupdetails,
  addNewLocation,
  handleLocation,
  handleChange,
  removeLocation,
  handleNext,
}) => {
  const [isNumberUserMoreErrorModal, setNumberUserMoreErrorModal] =
    useState(false);
  return (
    <Stack display="flex" height={"100%"}>
      <LocationFullContainer
        handleChange={handleChange}
        isFullScreen={isFullScreen}
        setFullScreen={setFullScreen}
        isNumberUserErrorModal={isNumberUserErrorModal}
        handleModalClose={handleModalClose}
        signupdetails={signupdetails}
        addNewLocation={addNewLocation}
        handleLocation={handleLocation}
        removeLocation={removeLocation}
        setNumberUserMoreErrorModal={() => setNumberUserMoreErrorModal(true)}
      />

      <CustomModal
        open={isNumberUserErrorModal}
        handleClose={handleModalClose}
        handleNext={handleNext}
      />

      <CustomModalUser
        open={isNumberUserMoreErrorModal}
        handleClose={() => setNumberUserMoreErrorModal(false)}
      />
      <FullScreenDialog
        open={isFullScreen}
        handleChange={handleChange}
        handleClose={setFullScreen}
        handleModalClose={handleModalClose}
        handleLocation={handleLocation}
        removeLocation={removeLocation}
        addNewLocation={addNewLocation}
        signupdetails={signupdetails}
        handleNext={handleNext}
      />
    </Stack>
  );
};
export default Location;
