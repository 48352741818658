import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
const CustomModalUser = ({ open, handleClose, handleNext }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      className="errorPopup"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { md: "572px", xs: "300px" },
        bgcolor: "#F0F1FF",
        borderRadius: "34px",
        border: "1px solid #8828FF",
        boxShadow: 24,
        pl: { md: "63px", xs: "23px" },
        pt: { md: "23px", xs: "13px" },
        pb: { md: "61px", xs: "31px" },
        pr: { xs: "23px", md: "0px" },
      }}
    >
      <Box
        className="closeBtn"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pr: { md: "26px", xs: "0px" },
        }}
      >
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>

      <Link
        to="https://helloteams.global/contact/"
        id="modal-modal-description"
        sx={{
          mt: "21px",
          mr: { md: "80px", xs: "10px" },
          color: "text.secondary",
          fontSize: { md: "20px", xs: "15px" },
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "149.5%",
        }}
      >
        Please contact our team for more information on plans.
      </Link>
    </Box>
  </Modal>
);

export default CustomModalUser;

