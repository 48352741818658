import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext, useRef } from "react";
import { ReactComponent as DownArrow } from "../../assets/svg/aDown.svg";
import { ReactComponent as Audio } from "../../assets/svg/audio.svg";
import { ReactComponent as Headsets } from "../../assets/svg/headsets.svg";
import { ReactComponent as Laptop } from "../../assets/svg/laptop.svg";
import { ReactComponent as Resize } from "../../assets/svg/resize.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";
import AutomatedStepper from "../../components/AutomatedStepper";
import AutomatedView from "../../components/AutomatedView";
import CallRecording from "../../components/CallRecording";
import ImageWithCounter from "../../components/ImageWithCounter";
import MeetingView from "../../components/MeetingView";
import { AppContext } from "../../store/store";
const AddonsFullContainer = ({
  handleChange,
  signupdetails,
  setActiveIndex,
  activeIndex,
  setFullScreen,
  isFullScreen,
}) => {
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const { currency } = useContext(AppContext);

  // const onScroll = () => {
  //   setTimeout(() => {
  //     const scrollTop = ref1.current.scrollTop;
  //     if (scrollTop < 50 && activeIndex !== 1) {
  //       setActiveIndex(1);
  //     } else if (scrollTop > 380 && scrollTop < 600 && activeIndex !== 2) {
  //       setActiveIndex(2);
  //     } else if (scrollTop > 600 && scrollTop < 1200 && activeIndex !== 3) {
  //       setActiveIndex(3);
  //     } else if (scrollTop > 1200 && activeIndex !== 4) {
  //       setActiveIndex(4);
  //     }
  //   }, 700);
  // };

  // useEffect(() => {
  //   if (activeIndex === 1) {
  //     ref2.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (activeIndex === 2) {
  //     ref3.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (activeIndex === 3) {
  //     ref4.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (activeIndex === 4) {
  //     ref5.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [activeIndex]);
  const isStepHighlighted = (step) => {
    switch (step) {
      case 1:
        if (signupdetails?.ivr_flow || activeIndex === 1) return true;
        break;
      case 2:
        if (
          signupdetails?.basic_call_user > 0 ||
          signupdetails?.premium_call_user > 0 ||
          activeIndex === 2
        )
          return true;
        break;
      case 3:
        if (
          signupdetails?.headset1 > 0 ||
          signupdetails?.headset2 > 0 ||
          signupdetails?.headset3 > 0 ||
          signupdetails?.handset > 0 ||
          activeIndex === 3
        )
          return true;
        break;
      case 4:
        if (signupdetails?.meeting_device > 0 || activeIndex === 4) return true;
        break;
      default:
        return false;
    }
  };
  return (
    <Box
      className="accordion addons"
      sx={{
        mt: "51px",
        display: "block",
        position: "relative",
        justifyContent: isFullScreen ? "center" : "unset",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          position: "relative",
          flexDirection: { sx: "space-between", md: "row" },
          justifyContent: isFullScreen ? "center" : "unset",
        }}
      >
        {!isFullScreen && (
          <AutomatedStepper
            data={signupdetails}
            currency={currency}
            activeIndex={activeIndex}
            handleActiveIndex={(index) => {
              setActiveIndex(index);
            }}
          />
        )}
        <Box width={"100%"} className="accordionBody">
          <Box
            onClick={() => setActiveIndex(activeIndex === 1 ? 0 : 1)}
            alignItems={"center"}
            sx={{
              display: { xs: "flex", md: "none" },
              mb: "32px",
              cursor: "pointer",
              justifyContent: "space-between",
              opacity: isStepHighlighted(1) ? 1 : 0.5,
              // opacity: activeIndex >= 1 && activeIndex !== 0 ? 1 : 0.5,
            }}
            className={`accordionBtn ${activeIndex === 1 ? "active" : ""}`}
          >
            <Box sx={{ width: "51px" }}>
              <Settings />
            </Box>
            <Typography
              sx={{
                flex: 1,
                pl: "16px",
                color: "#7B83EB",
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Heebo",
                fontWeight: "700",
                lineHeight: "109.375%",
              }}
            >
              Automated Attendants and IVR Call Flows
            </Typography>
            <DownArrow />
          </Box>
          {(activeIndex === 1 || isFullScreen) && (
            <AutomatedView
              ref={ref2}
              isFullScreen={isFullScreen}
              handleChange={handleChange}
              signupdetails={signupdetails}
            />
          )}
        </Box>
        <Box width={"100%"} className="accordionBody">
          <Box
            onClick={() => setActiveIndex(activeIndex === 2 ? 0 : 2)}
            alignItems={"center"}
            sx={{
              display: { xs: "flex", md: "none" },
              mb: "32px",
              cursor: "pointer",
              justifyContent: "space-between",
              opacity: isStepHighlighted(2) ? 1 : 0.5,
              // opacity: activeIndex >= 2 && activeIndex !== 0 ? 1 : 0.5,
            }}
            className={`accordionBtn ${activeIndex === 2 ? "active" : ""}`}
          >
            <Box sx={{ width: "51px" }}>
              <Audio />
            </Box>
            <Typography
              sx={{
                flex: 1,
                pl: "16px",
                color: "#7B83EB",
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Heebo",
                fontWeight: "700",
                lineHeight: "109.375%",
              }}
            >
              Call Recording
            </Typography>{" "}
            <DownArrow />
          </Box>
          <Box width={"100%"} className="accordionContent">
            {(activeIndex === 2 || isFullScreen) && (
              <CallRecording
                ref={ref3}
                handleChange={handleChange}
                signupdetails={signupdetails}
                isFullScreen={isFullScreen}
              />
            )}{" "}
          </Box>
        </Box>
        {signupdetails.location.length > 0 &&
          signupdetails.location.find((item) => item?.country_code === "AU") &&
          currency === "AUD" && (
            <>
              <Box width={"100%"} className="accordionBody">
                <Box
                  onClick={() => setActiveIndex(activeIndex === 3 ? 0 : 3)}
                  alignItems={"center"}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    mb: "32px",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    opacity: isStepHighlighted(3) ? 1 : 0.5,
                    // opacity: activeIndex >= 3 && activeIndex !== 0 ? 1 : 0.5,
                  }}
                  className={`accordionBtn ${
                    activeIndex === 3 ? "active" : ""
                  }`}
                >
                  <Box sx={{ width: "51px" }}>
                    <Headsets />
                  </Box>
                  <Typography
                    sx={{
                      flex: 1,
                      pl: "16px",
                      color: "#7B83EB",
                      fontFamily: "Heebo",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "109.375%",
                    }}
                  >
                    Headsets & Handsets
                  </Typography>{" "}
                  <DownArrow />
                </Box>
                <Box width={"100%"} className="accordionContent">
                  {(activeIndex === 3 || isFullScreen) && (
                    <ImageWithCounter
                      ref={ref4}
                      handleChange={handleChange}
                      signupdetails={signupdetails}
                      isFullScreen={isFullScreen}
                    />
                  )}{" "}
                </Box>
              </Box>
              <Box width={"100%"} className="accordionBody">
                <Box
                  onClick={() => setActiveIndex(activeIndex === 4 ? 0 : 4)}
                  alignItems={"center"}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    cursor: "pointer",
                    justifyContent: "space-between",
                    opacity: isStepHighlighted(4) ? 1 : 0.5,
                    // opacity: activeIndex >= 4 && activeIndex !== 0 ? 1 : 0.5,
                  }}
                  className={`accordionBtn ${
                    activeIndex === 4 ? "active" : ""
                  }`}
                >
                  <Box sx={{ width: "51px" }}>
                    <Laptop style={{ height: "52px" }} />
                  </Box>
                  <Typography
                    sx={{
                      flex: 1,
                      pl: "16px",
                      color: "#7B83EB",
                      fontSize: "16px",
                      fontFamily: "Heebo",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "109.375%",
                    }}
                  >
                    Meeting room devices
                  </Typography>{" "}
                  <DownArrow />
                </Box>
                <Box width={"100%"} className="accordionContent">
                  {(activeIndex === 4 || isFullScreen) && (
                    <MeetingView
                      ref={ref5}
                      handleChange={handleChange}
                      signupdetails={signupdetails}
                      isFullScreen={isFullScreen}
                    />
                  )}{" "}
                </Box>
              </Box>
            </>
          )}
      </Box>

      {!isFullScreen && (
        <IconButton
          className="btn-fullscreen btn-fullscreen--addons"
          sx={{
            position: "fixed",
            bottom: "11vh",
            right: "50px",
            display: { xs: "none", md: "flex" },
          }}
          onClick={setFullScreen}
        >
          <Resize />
        </IconButton>
      )}
    </Box>
  );
};
export default AddonsFullContainer;
