import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../assets/svg/left-back.svg";
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import PaypalIcon from "../../assets/png/paypal.png";
import MasterCard from "../../assets/png/masterCard.png";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();
  const amount = JSON.parse(localStorage.getItem("total_amount")) || [];
  const finalAmount = Array.isArray(amount) 
      ? parseFloat(amount.reduce((total, item) => total + (item.price * item.quantity || 0), 0).toFixed(2))
      : 0;
  const taxes = parseFloat(JSON.parse(localStorage.getItem("taxes"))) || 0;
  const shipping =
    parseFloat(JSON.parse(localStorage.getItem("shipping"))) || 0;
  const isAbove720 = useMediaQuery("(min-width:900px)");

  // State for each form field
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
    setCardNumber(value);
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d{2})$/, "$1 / $2");
    }

    setExpiry(value);
  };
  const handleSubmit = async (e) => {
    console.log("button clicked");

    e.preventDefault();

    if (!cardNumber || !expiry || !cvv || !nameOnCard || !country) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    try {
      const body = JSON.stringify({
        cardNumber: cardNumber ? cardNumber.replace(/\s+/g, "") : "",
        expiry: expiry ? expiry.replace(/\s+/g, "") : "",
        cvv: cvv ? cvv.replace(/\s+/g, "") : "",
        nameOnCard: nameOnCard || "",
        country: country || "",
      });
      const response = await fetch("https://your-api-endpoint.com/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Payment successful:", result);
        alert("Payment was successful!");
        navigate("/success");
      } else {
        throw new Error("Payment failed");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("There was an error processing your payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const [paymentMethod, setPaymentMethod] = useState("paypal");
  // const handlePaymentMethodChange = (method) => {
  //   setPaymentMethod(method);
  // };
  return (
    <Box
      sx={{
        maxWidth: "1159px",
        mx: "auto",
        p: isAbove720 ? "0" : "20px",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          bgcolor: "#ebf6ff",
        }}
      >
        {/* Left Section - Shipping and Order Summary */}
        <Grid
          container
          spacing={6}
          marginTop="0"
          padding="0"
          width="100%"
          marginLeft="0"
          marginBottom={isAbove720 ? 0 : "20px"}
        >
          <Grid
            item
            xs={12}
            md={6}
            className="grid-1"
            width={isAbove720 ? "45%" : "100%"}
          >
            <Typography
              variant="h6"
              lineHeight="32px"
              fontSize="20px"
              fontFamily="DM Sans"
              fontWeight="700"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                fontFamily: "DM Sans",
                lineHeight: "26.4px",
                color: isAbove720 ? "#4B54A2" : "#0C2A52",
                px: isAbove720 ? 0 : "14px",
                pt: isAbove720 ? 0 : 1,
                marginBottom: isAbove720 ? "15px" : "20px",
              }}
              marginTop={isAbove720 ? 0 : "20px"}
            >
              Shipping Information
            </Typography>
            <Typography
              variant="body1"
              fontWeight="600"
              fontFamily="Heebo"
              padding={isAbove720 ? "0" : "0 14px"}
              paragraph
              style={{
                color: "#666666",
                lineHeight: "22.4px",
                marginBottom: "20px",
              }}
            >
              XYZ Communications Pvt Ltd.
            </Typography>

            <Typography
              variant="h6"
              lineHeight="32px"
              fontWeight="700"
              fontSize="20px"
              fontFamily="DM Sans"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                lineHeight: "26.4px",
                marginBottom: "20px",
                px: isAbove720 ? 0 : "14px",
                color: isAbove720 ? "#4B54A2" : "#0C2A52",
              }}
            >
              Order Summary
            </Typography>
            <Typography
              variant="body1"
              fontFamily="Heebo"
              padding={isAbove720 ? "0" : "0 19px"}
              paragraph
              style={{
                color: "#666666",
                lineHeight: "22.4px",
                fontWeight: "400",
                marginBottom: "20px",
                fontSize: "14px",
              }}
            >
              You will be billed immediately for your once-off setup costs. Your
              monthly costs will be charged at the end of your first billing
              cycle.
            </Typography>

            <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />

            {/* Order Details */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: isAbove720 ? "0" : "0 15px",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "#666666", lineHeight: "22.4px" }}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Heebo",
                  marginBottom: "6px",
                }}
              >
                Sub Total:
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: "#5E718B",
                  lineHeight: "22.4px",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                }}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Heebo",
                }}
              >
                ${finalAmount}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Heebo",
                px: isAbove720 ? 0 : "15px",
                marginBottom: "6px",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "#666666", lineHeight: "22.4px", fontFamily: "Heebo" }}
              >
                Taxes:
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: "#5E718B",
                  lineHeight: "22.4px",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                }}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Heebo",
                }}
              >
                ${taxes}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: isAbove720 ? "0" : "0 15px",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "#666666", lineHeight: "22.4px" }}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Heebo",
                }}
              >
                Shipping & Handling:
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: "#5E718B",
                  lineHeight: "22.4px",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                }}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Heebo",
                }}
              >
                ${shipping}
              </Typography>
            </Box>

            <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                padding={isAbove720 ? "0" : "0 14px"}
                style={{ color: "#666666", lineHeight: "22.4px" }}
                sx={{
                  fontSize: "14px",
                  fontFamily: "Heebo",
                  fontWeight: "400",
                }}
              >
                Grand Total:
              </Typography>
              <Typography
                variant="h6"
                padding={isAbove720 ? "0" : "0 14px"}
                style={{
                  color: "#5E718B",
                  lineHeight: "22.4px",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                }}
                sx={{
                  fontSize: "16px",
                  fontFamily: "Heebo",
                  lineHeight: "25.6px",
                }}
              >
                ${shipping + taxes + finalAmount}
              </Typography>
            </Box>
            {!isAbove720 && <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />}
          </Grid>
          {isAbove720 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "#7B83EB",
                height: "42vh", // Set height relative to the viewport height
                alignSelf: "center",
              }}
            />
          )}
          {/* Right Section - Payment Method */}
          <Grid
            item
            xs={12}
            md={6}
            className="grid-2"
            width={isAbove720 ? "55%" : "100%"}
          >
            {/* Payment Method Buttons */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              padding={isAbove720 ? "0" : "0 14px"}
            >
              <Button
                variant={"outlined"}
                sx={{
                  width: "100%",
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  border: "1px solid",
                  fontFamily: "Heebo",
                }}
              >
                <img src={PaypalIcon} />
              </Button>
            </Stack>

            {/* OR Divider */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ margin: isAbove720 ? "20px 0" : "15px 0 10px 0" }}
            >
              <Divider sx={{ flex: 1, borderColor: "#7B83EB" }} />

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22.4px",
                  fontFamily: "Heebo",
                  verticalAlign: "middle",
                  color: "#0C2A52",
                }}
              >
                OR
              </Typography>

              <Divider sx={{ flex: 1, borderColor: "#7B83EB" }} />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="start"
              padding={isAbove720 ? "0" : "0 8px"}
              paddingBottom={isAbove720 ? "0" : "8px"}
            >
              <RadioGroup>
                <FormControlLabel
                  value="Credit Card / Debit Card"
                  control={<Radio sx={{ color: "#7B83EB" }} />}
                  label="Credit Card / Debit Card"
                  fontWeight="400"
                  lineHeight="22.4px"
                  fontFamily="Heebo"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                    paddingLeft: "9px",
                  }}
                />
              </RadioGroup>
            </Stack>
            <Box padding={isAbove720 ? "0" : "0 14px"}>
              <form onSubmit={handleSubmit}>
                <TextField
                  className="form-control-text-field"
                  fullWidth
                  label="Card Number"
                  variant="outlined"
                  margin="normal"
                  placeholder="1111 2222 3333 4444"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  required
                  inputProps={{
                    maxLength: 19,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7B83EB",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: <img src={MasterCard} alt="mastercard" />,
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Expiry"
                      variant="outlined"
                      margin="normal"
                      placeholder="MM / YY"
                      value={expiry}
                      onChange={handleExpiryChange}
                      required
                      inputProps={{
                        maxLength: 4,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#7B83EB",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CVV"
                      variant="outlined"
                      margin="normal"
                      placeholder="123"
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                      required
                      inputProps={{
                        maxLength: 3,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#7B83EB",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Name on the card"
                  variant="outlined"
                  margin="normal"
                  placeholder="John Doe"
                  value={nameOnCard}
                  onChange={(e) => setNameOnCard(e.target.value)}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7B83EB",
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  select
                  label="Country"
                  variant="outlined"
                  margin="normal"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                  sx={{
                    marginBottom: isAbove720 ? "0" : "25px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7B83EB",
                      },
                    },
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select a country</option>
                  <option value="us">United States</option>
                  {/* Add other countries as needed */}
                </TextField>
              </form>
            </Box>
            {/* Back and Pay Now Buttons */}
            {isAbove720 && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  variant="outlined"
                  borderRadius="14.5px"
                  onClick={() => navigate("/")}
                >
                  <BackArrow />
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  borderRadius="14.5px"
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Pay Now"}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>

      {!isAbove720 && (
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            bgcolor: "#ebf6ff",
          }}
        >
          <Grid
            item
            xs={6}
            className="calculation-box-checkout"
            sx={{
              border: { sm: "1px solid #7B83EB80", xs: "none" },
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "#ebf6ff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                fontWeight="400"
                lineHeight="22.4px"
                color="#666666"
                fontFamily="Heebo"
                sx={{ fontSize: "14px" }}
              >
                Grand Total: ${finalAmount}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  mr: 1,
                  borderRadius: "14.5px",
                  padding: "10px",
                  borderColor: "#7B83EB80",
                  color: "#7B83EB",
                  maxWidth: "83px",
                  "&:hover": {
                    borderColor: "#7B83EB",
                    backgroundColor: "#eef1ff",
                  },
                  textTransform: "none",
                  fontFamily: "Heebo",
                }}
              >
                <BackArrow />
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "14.5px",
                  padding: "10px 0",
                  backgroundColor: "#7B83EB",
                  "&:hover": { backgroundColor: "#5a65e6" },
                  lineHeight: "22.4px",
                  textAlign: "center",
                  minWidth: "108px",
                  fontFamily: "Heebo",
                }}
                class="checkout-button-class"
                onClick={handleSubmit}
                disabled={loading}
                // onClick={() => onSubmit(false)}
              >
                {loading ? "Processing..." : "Pay Now"}
              </Button>
            </Box>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default Payment;
