import { Box, Stack } from "@mui/material";
import React from "react";
import InputText from "../../components/InputText/InputText";
import PhoneNumberInput from "../../components/InputTextWithDD/PhoneNumberInput";

const Started = ({ signupdetails, handleChange, onPhoneNumberChange }) => {
  return (
    <Stack width={"100%"}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 0.5fr", xs: "1.5fr" },
          gap: { md: "34px", xs: "10px" },
        }}
      >
        <InputText
          required
          value={signupdetails?.first_name}
          name="first_name"
          placeholder={"First Name"}
          onChange={(event) => handleChange("first_name", event.target.value)}
        />
        <InputText
          required
          name="last_name"
          value={signupdetails?.last_name}
          placeholder={"Last Name"}
          onChange={(event) => handleChange("last_name", event.target.value)}
        />
        <span></span>
        <InputText
          required
          name="email"
          type="email"
          value={signupdetails?.email}
          placeholder={"Email"}
          onChange={(event) => handleChange("email", event.target.value)}
        />
        <PhoneNumberInput
          required
          placeholder="Phone"
          name="phone"
          type="number"
          onPhoneNumberChange={onPhoneNumberChange}
          defaultValue={signupdetails?.phone}
        />
        <span></span>
        <InputText
          required
          name="company_name"
          placeholder="Company Name"
          value={signupdetails?.company_name}
          onChange={(event) => handleChange("company_name", event.target.value)}
        />
      </Box>
    </Stack>
  );
};
export default Started;
