import { Box, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import ToggleSwitch from "../ToggleSwitch";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "180px",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: 12,
  },
}));
const ImageTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "1000px",
    maxWidth: "none",
  },
}));
const CustomSwitch = ({
  labelName,
  isInfoIcon = true,
  isRequired = false,
  tooltipTitle = "",
  handleOnChange,
  imageTooltip,
  ...props
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} sx={{ marginBottom: "11px" }} className="ivrCallToggle">
      <Typography
        htmlFor="bootstrap-input"
        sx={{
          color: "text.secondary",
          fontSize: "14px",
          fontWeight: "400",
          paddingRight: "10px",
          display: "flex",
          fontFamily: "Heebo",
          alignItems: "center",
        }}
      >
        {labelName}
        {isRequired && <span style={{ color: "red" }}>*</span>}
        {isInfoIcon &&
          (imageTooltip ? (
            <ImageTooltip title={tooltipTitle} placement="top">
              <Info
                style={{ marginLeft: "7px", width: "16px", height: "16px" }}
              />
            </ImageTooltip>
          ) : (
            <LightTooltip title={tooltipTitle} placement="top">
              <Info
                style={{ marginLeft: "7px", width: "16px", height: "16px" }}
              />
            </LightTooltip>
          ))}
      </Typography>
      <ToggleSwitch handleOnChange={handleOnChange} {...props} />
    </Box>
  );
};
export default CustomSwitch;
