import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { ReactComponent as Down } from "../../assets/svg/down.svg";
import { ReactComponent as Up } from "../../assets/svg/up.svg";
import country from "../../utils/Country";

const FlagsCarousel = ({
  signupdetails,
  handleChange,
  handleLocation,
  activeItem,
  setCurrency,
  isSelectedCountry,
  setSelectedCountry,
}) => {
  const [carousalItems, setCarousalItems] = useState(3);

  useEffect(() => {
    function adjustVisibleItems() {
      if (window.innerWidth > 765 && window.innerHeight > 920) {
        setCarousalItems(4);
      } else {
        setCarousalItems(3);
      }
    }

    adjustVisibleItems();

    window.addEventListener("resize", adjustVisibleItems);

    return () => {
      window.removeEventListener("resize", adjustVisibleItems);
    };
  }, []);

  const ref = useRef(null);
  return (
    <Stack
      className="flagSliderPart"
      sx={{
        width: { xs: "100%", md: "28%" },
        display: { xs: isSelectedCountry ? "none" : "flex", md: "flex" },
      }}
      alignItems="flex-start"
    >
      <IconButton onClick={() => ref.current?.slidePrev()} sx={{ ml: "5px" }}>
        <Up />
      </IconButton>
      <Box
        display={"flex"}
        sx={{ width: "100%", overflow: "hidden" }}
        // sx={{ width: "100%", height: { md: "calc(100% - 120px)", xs: "290px" }, overflow: 'hidden' }}
      >
        <Box sx={{ width: "100%" }}>
          <Carousel
            itemPadding={[15, 0, 15, 0]}
            verticalMode
            itemsToShow={carousalItems}
            ref={ref}
          >
            {country.map((item, index) => (
              <Box
                onClick={() => {
                  handleChange("country_code", item);
                  if (signupdetails.location.length === 1) {
                    setCurrency(
                      item?.currency?.code === "CAD" ||
                        item?.currency?.code === "SGD"
                        ? "USD"
                        : item?.currency?.code
                    );
                  }
                  handleLocation(activeItem, "is_selected_country", item);
                  setSelectedCountry(true);
                }}
                key={item.name + index}
                className="flag-item-wrapper"
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                // marginTop={"32px"}
                sx={{
                  cursor: "pointer",
                  opacity:
                    signupdetails.location[activeItem]?.country_code === ""
                      ? 0.5
                      : signupdetails.location[activeItem]?.country_code !==
                        item?.code
                      ? 0.5
                      : 1,
                }}
              >
                <Box
                  className={"flag-icon"}
                  sx={{ borderRadius: 5, display: "flex" }}
                >
                  {item.flag}
                </Box>
                <Typography
                  sx={{
                    marginLeft: 2,
                    paddingLeft: "10px",
                    color: "text.light",
                    fontSize: "16px !important",
                    fontStyle: "normal",
                    fontWeight: "700 !important",
                    lineHeight: "normal !important",
                    fontFamily: "Heebo",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
      <IconButton
        onClick={() => ref.current?.slideNext()}
        sx={{ ml: "5px", marginBottom: "-5px" }}
      >
        <Down />
      </IconButton>
    </Stack>
  );
};
export default FlagsCarousel;

